import React, { Suspense, lazy } from 'react';
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";

import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

import PageContact from "./routes/contact/index";
import './fonts/kenteken.ttf';
import { Provider } from "react-redux";
import store from "./store/index";

import Page404 from "./routes/page-404";
import ProtectedRoute from "./routes/ProtectedRoute";
import ProtectedCrmRoute from "./routes/ProtectedCrmRoute";

import ScrollToTop from 'components/ui/ScrollToTop';
import PageHome from 'routes/index'; 

import ReactGA from "react-ga4";

import Auth from 'components/Auth/Auth';
import Introductie from 'routes/introductie';
import Bedankpagina from 'routes/bedankpagina';
import Container from "components/hoc/Container";
import Content from "components/Content/Content";
import DealList from 'components/Deal/DealList';
import DealDetail from 'routes/admin/deal-detail';
import CustomerDetail from 'routes/admin/customer-detail';
import DealerDetail from 'routes/crm/dealers/dealer-detail';
import Dealers from 'routes/crm/dealers';
import NieuweDealer from 'routes/crm/dealers/nieuwe-dealer';
import Inbox from 'routes/crm/inbox';
import GetekendeOffertes from 'routes/crm/getekende-offertes';
import ContractAfronding from 'routes/crm/contract-afronding';
import Sales from 'routes/crm/sales';
import MyProfilePage from 'routes/crm/mijn-profiel';
import AdminDealList from 'components/Tables/AdminDealList';
import VerkoopDetail from 'routes/crm/verkopen/verkoop-detail';
import Contacts from 'routes/crm/customer';
import Partners from 'routes/crm/partners';
import PartnerDetail from 'routes/crm/partners/partner-detail';
import NieuwePartner from 'routes/crm/partners/nieuwe-partner';
import Selectie from 'routes/crm/selectie';
import Export from 'routes/crm/export';
import Activities from 'routes/crm/activiteiten';
import Gebruikers from 'routes/crm/gebruikers';
import NewAdminForm from 'components/Forms/NewAdminForm/NewAdminForm';
import ProtectedSuperAdminRoute from 'routes/crm/ProtectedSuperAdminRoute';
import Tokens from 'routes/crm/tokens';
import RoleForm from 'components/Forms/RoleForm/RoleForm';
import Crm from 'Crm';
import ChangeLog from 'routes/crm/changelog';
import TermsAndConditions from 'routes/crm/terms-and-conditions';
import CalculatorSettings from 'routes/crm/calculator';
import StapVoertuigGegevens from 'routes/verzekering/voertuiggegevens';
import StapCalculator from 'routes/financiering/financial-lease/calculator';
import StapContact from 'routes/financiering/financial-lease/contactgegevens';
import StapBedankt from 'routes/financiering/financial-lease/bedankpagina';
import BtwFinanciering from 'routes/financiering/btw-financiering/index';
import BtwFinancieringBedankt from 'routes/financiering/btw-financiering/bedankpagina';
import VerzekerenStap1 from 'routes/verzekering';
import VerzekerenForm from 'routes/verzekering/klantgegevens';
import PageProfiel from 'routes/profiel';
import Statistieken from 'routes/statistieken';
import StapBtwMarge from 'routes/financiering/financial-lease/btw-marge';
import HomeStap from 'routes/financiering/financial-lease';
import CrmPage from 'routes/crm';
import PageInsuranceThankYou from 'routes/verzekering/bedankpagina';
import CustomerFormPage from 'components/Forms/CustomerForm/CustomerFormPage';
import StatusPage from 'routes/crm/status';
import MailTemplateManager from 'routes/crm/components/MailTemplateManager/MailTemplateManager';

const SalesDashboard = React.lazy(() => import("components/Dashboard/SalesDashboard"));
const ManagementDashboard = React.lazy(() => import("components/Dashboard/ManagementDashboard"));

const PageVoorraadModule = lazy(() => import('routes/voorraadmodule'));
// const Charts = lazy(() => import('components/Dashboard/Charts'));

const router = createBrowserRouter([
  {path: "/", element: <App />, errorElement: <Page404 />, children: [
    {index: true, element: <PageHome />},
    {path: "login", element: <Auth case="login" title="Inloggen" />},
    {path: "activeren", element: <Auth case="activate" title="Account activeren" />},
    {path: "account-activeren", element: <Auth case="activate-subuser" title="Account activeren" />},
    {path: "wachtwoord-vergeten", element: <Auth case="forgotpwd" title="Wachtwoord vergeten" />},
    {path: "wachtwoord-reset", element: <Auth case="resetpwd" title="Reset wachtwoord" />},
    {path: "autobedrijf-aanmelden", element: <Outlet />, children: [
      {index: true, element: <Introductie />},
        {path: "bedankpagina", element: <Bedankpagina />}
    ]},
    {path: "contact", element: <PageContact />},
    {path: "financiering", element: <ProtectedRoute><Outlet /></ProtectedRoute>, children: [
      {path: "financial-lease", children: [
          {index: true, element: <HomeStap />},
          {path: "voertuiggegevens", element: <StapVoertuigGegevens />}, 
          {path: "btw-marge", element: <StapBtwMarge />},
          {path: "calculator", element: <StapCalculator />},
          {path: "klantgegevens", element: <StapContact />},
          {path: "bedankpagina", element: <StapBedankt />},
        ]},
        {path: "btw-financiering", children: [
          {index: true, element: <BtwFinanciering />},
          {path: "bedankpagina", element: <BtwFinancieringBedankt />},
        ]},
    ]}, 
    {path: "verzekering", element: <ProtectedRoute><Outlet /></ProtectedRoute>, children: [
      {index: true, element: <VerzekerenStap1 />},
      {path: "voertuiggegevens", element: <StapVoertuigGegevens />},
      {path: "btw-marge", element: <StapBtwMarge />},
      {path: "klantgegevens", element: <VerzekerenForm />},
      {path: "bedankpagina", element: <PageInsuranceThankYou />}
    ]},
    {path: "profiel", element: <ProtectedRoute><PageProfiel /></ProtectedRoute>},
    {path: "voorraadmodule", element: <ProtectedRoute><Suspense fallback={"Laden.."}><PageVoorraadModule /></Suspense></ProtectedRoute>},
    {path: "verkopen", element: <ProtectedRoute><Outlet /></ProtectedRoute>, children: [
      {index: true, element: <><Container type="fullscreen"><Content title="Verkopen" /></Container><div className="mx-auto"><DealList /></div></>},
      {path: ":dealID", element: <DealDetail />},
    ]},
  ]},
  {path: "/crm", errorElement: <>Je bent niet ingelogd!</>, element: <ProtectedCrmRoute><Crm /></ProtectedCrmRoute>, children: [
      {index: true, element: <CrmPage />},
        {path: "inbox", element: <Inbox />},
        {path: "getekende-offertes", element: <GetekendeOffertes />},
        {path: "contract-afronding", element: <ContractAfronding />},
        {path: "sales", element: <Sales />},
        {path: "mijn-profiel", element: <MyProfilePage />},
        {path: "notificaties", element: <div>Notificaties</div>},
        {path: "activiteiten", element: <Activities />},
        {path: "dashboard", element: <Suspense fallback={"Laden.."}><ManagementDashboard /></Suspense>},
        {path: "dashboard-sales", element: <Suspense fallback={"Laden.."}><SalesDashboard /></Suspense>},
        {path: "todo", element: "todo component here"},
        {path: "status", element: <StatusPage />},
        {path: "export", element: <Export />},
        {path: "selectie", element: <Selectie />},
        {path: "dealers", element: <Outlet />, children: [
          {index: true, element: <Dealers />},
          {path:":dealerID", element: <DealerDetail />},
          {path:"nieuwe-dealer", element: <NieuweDealer />},
        ]},
        {path: "verkopen", element: <Outlet />, children: [
          {index: true, element: <AdminDealList isArchive={false} />},
          {path: ":dealID", element: <VerkoopDetail />},
        ]},
        {path: "klanten", element: <Outlet />, children: [
          {index: true, element: <Contacts />},
          {path: ":customerID", element: <CustomerFormPage />},
        ]},
        {path: "partners", element: <Outlet />, children: [
          {index: true, element: <Partners />},
          {path: ":partnerID", element: <PartnerDetail />},
          {path: "nieuwe-partner", element: <NieuwePartner />},
        ]},
        {path: "gebruikers", element: <ProtectedSuperAdminRoute><Outlet /></ProtectedSuperAdminRoute>, children: [
          {index: true, element: <Gebruikers />},
          {path: ":userID", element: <NewAdminForm />},
        ]},
        {path: "tokens", element: <ProtectedSuperAdminRoute><Tokens /></ProtectedSuperAdminRoute>},
        {path: "changelog", element: <ProtectedSuperAdminRoute><ChangeLog /></ProtectedSuperAdminRoute>},
        {path: "algemene-voorwaarden", element: <ProtectedSuperAdminRoute><TermsAndConditions /></ProtectedSuperAdminRoute>},
        {path: "rollen", element: <ProtectedSuperAdminRoute><RoleForm /></ProtectedSuperAdminRoute>},
        {path: "mailtemplates", element: <ProtectedSuperAdminRoute><MailTemplateManager /></ProtectedSuperAdminRoute>},
        // {path: "calculator", element: <ProtectedSuperAdminRoute><CalculatorSettings /></ProtectedSuperAdminRoute>}
    ]}
]);

createRoot(document.getElementById("root")).render(
  <Provider store={store}><RouterProvider router={router}><ScrollToTop /></RouterProvider></Provider>
);
ReactGA.initialize("G-J8TVR886LJ");