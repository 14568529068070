import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import InputRange from "components/ui/InputRange/InputRange";
import Button from "components/ui/Button/Button";
import Modal from "components/hoc/Modal";
import Loader from "components/ui/Loader/Loader";
import useCalculator from "components/Calculator/use-calculator"
import InputCommission from "./InputCommission";

import EarlierCalculated from "./EarlierCalculated";
import { getBankSelectOptions, valuesToNoDot } from "./helpers";
import Input from "components/ui/Input/Input";
import Select from "components/ui/Select/Select";
import { formatThousandNotation, formattedPMT_v2, intTo2DecStr } from "lib/calc";
import CalcSummary from "./CalcSummary";
import { getFullBankNameFromPrefix } from "lib/voertuig";
import { uiActions } from "store/ui-slice";
import { dealActions } from "store/deal-slice";
import CalcDebug from "./CalcDebug";
import { noDotAndInteger } from "lib/helpers";

import useBankApi from "./use-bank-api";
import useAuth from "hooks/use-auth";
import useDealV2 from "hooks/deal/use-deal_v2";
import useActivity from "hooks/activity/use-activity";

const CalculatorV3 = (props) => {
    const debug = false;
    const dispatch = useDispatch();
    const { role } = useAuth();

    const navigate = useNavigate();
    const { createActivity } = useActivity();
    const { calcInput, bankSettings, changeBank, changeCalcField, changeInterest, changeInterestFromRange, changeVehicleType, loadCalculationFromStorageData, setTouchedField } = useCalculator(props);

    const { patchDeal } = useDealV2();

    const { calcDetail } = useSelector(state => state.deal);

    const transformCalcObjToCorrectFormat = (calcObj) => {
        return {
                durationMax: calcObj?.durationMax,
                age: calcObj?.age,
                interest: +calcObj?.interest,
                commissionAmountDealer: (calcObj?.commissionAmountDealer ? parseFloat(calcObj.commissionAmountDealer) : 0),
                commissionAmountSLD: (calcObj?.commissionAmountSLD ? parseFloat(calcObj.commissionAmountSLD) : 0),
                amountpurchase: calcObj?.amountpurchase ? noDotAndInteger(calcObj.amountpurchase) : 0,
                amountpurchaseExclVAT: calcObj?.amountpurchaseExclVAT ? noDotAndInteger(calcObj.amountpurchaseExclVAT) : 0,
                leaseAmount: calcObj?.leaseAmount ? noDotAndInteger(calcObj.leaseAmount) : 0,
                tradeInAmount: calcObj?.tradeInAmount ? noDotAndInteger(calcObj.tradeInAmount) : 0,
                finalpayment: calcObj?.finalpayment ? noDotAndInteger(calcObj.finalpayment) : 0,
                downpayment: calcObj?.downpayment ? noDotAndInteger(calcObj.downpayment) : "",
                repayment: calcObj?.repayment ? noDotAndInteger(calcObj.repayment) : 0,
                totalToPay: calcObj?.totalToPay ? noDotAndInteger(calcObj.totalToPay) : 0,
                duration: calcObj?.duration ? +calcObj.duration : 0,
                monthlyamount: calcObj?.monthlyamount ? parseFloat(calcObj.monthlyamount) : 0,
                vat: calcObj?.vat ? noDotAndInteger(calcObj.vat) : 0,
                provisiePercentage: calcObj?.provisiePercentage ? +calcObj.provisiePercentage : 0
            }
    }

    const updateFinanceHandler = async (reassessment=false, useProposalInOffer=false) => {
        // useProposalInOffer: Voorstel gebruiken in offerte
        // reassessment: Herbeoordeling aanvragen

        var { amountpurchase, amountpurchaseExclVAT, downpayment, duration, finalpayment, leaseAmount, provisiePercentage, repayment, totalToPay, tradeInAmount, vat, interest } = valuesToNoDot({
            amountpurchase: calcInput.amountpurchase,
            amountpurchaseExclVAT: calcInput.amountpurchaseExclVAT,
            downpayment: calcInput.downpayment,
            duration: calcInput.duration,
            finalpayment: calcInput.finalpayment,
            leaseAmount: calcInput.leaseAmount,
            provisiePercentage: calcInput.provisiePercentage,
            repayment: calcInput.repayment,
            totalToPay: calcInput.totalToPay,
            tradeInAmount: calcInput.tradeInAmount,
            vat: calcInput.vat,
            interest: calcInput.interest
        }, false);

        var dataToChange = {
            commissionAmountSLD: calcInput.commissionAmountSLD,
            commissionAmountDealer: calcInput.commissionAmountDealer,
            administrationCost: administrationCost ? parseFloat(administrationCost?.replace(",", ".")) : 0,
            amountpurchase, amountpurchaseExclVAT, downpayment, duration, finalpayment, leaseAmount, provisiePercentage, repayment, totalToPay, tradeInAmount, vat, interest,
            monthlyamount: parseFloat(calcInput.monthlyamount),
            contractInLossing: repaymentDetails.contractInLossing,
            kentekenInLossing: repaymentDetails.kentekenInLossing,
            bankInLossing: repaymentDetails.bankInLossing,
            ...(reassessment && {phase: "Uw aanvraag is ontvangen", relatedID: props.dealID}), // HERBEOORDELING? phase = "Uw aanvraag is ontvangen", relatedID: id
            ...(useProposalInOffer && {chosenBank: calcInput.chosenBank})
        }
        var resp = await patchDeal(props.dealID, dataToChange);
        if(resp==="SUCCESS") {
            var category = "Financieringsgegevens";
            var actionType = "update";
            if(reassessment) actionType = "reassess";
            if(useProposalInOffer) {
                actionType = "use";
                category = getFullBankNameFromPrefix(calcInput.chosenBank);
            }
            createActivity("Deal", actionType, {dealID: props.dealID}, dataToChange, category);
            props.onUpdate();
            props.onClose();
        }
    }

    const updateBankData = async (prefix) => {
        var finalPayment = noDotAndInteger(calcInput.finalpayment);
        var contractNumber = bankResp.contractNumber || "";
        var requestNumber = contractNumber;

        if(prefix==="df") {
            // minimal finalPayment = 1
            if(!finalPayment) finalPayment = 1;
            // contractNumber from FE http request
            contractNumber = responseData.contractNumber || props.contractNumber || "";
            requestNumber = responseData.requestNumber || props.requestNumber || contractNumber || "";
        }
        
        props.bankDetail.provisiePercentage = +calcInput.provisiePercentage;
        
        var bankData = {
            administrationCost: administrationCost ? parseFloat(administrationCost?.replace(",", ".")) : 0,
            [prefix+"Message"]: props.message || "",
            [prefix+"Response"]: props.response || "",
            [prefix+"Status"]: bankResp.status || "",
            [prefix+"ResponseDutch"]: props.responseDutch || "",
            [prefix+"RequestNumber"]: requestNumber,
            [prefix+"AmountPurchase"]: noDotAndInteger(calcInput.amountpurchase),
            [prefix+"AmountPurchaseExclVat"]: calcInput.amountpurchaseExclVAT ? noDotAndInteger(calcInput.amountpurchaseExclVAT) : "",
            [prefix+"ContractNumber"]: contractNumber,
            [prefix+"DownPayment"]: noDotAndInteger(calcInput.downpayment),
            [prefix+"LeaseAmount"]: noDotAndInteger(calcInput.leaseAmount),
            [prefix+"Vat"]: noDotAndInteger(calcInput.vat),
            [prefix+"TradeInAmount"]: noDotAndInteger(calcInput.tradeInAmount),
            [prefix+"Interest"]: +calcInput.interest,
            [prefix+"FinalPayment"]: finalPayment,
            [prefix+"Duration"]: +calcInput.duration,
            [prefix+"MonthlyAmount"]: parseFloat(calcInput.monthlyamount), // Number(calcInput.monthlyamount.replace(/\./g,"").replace(",",".")),
            [prefix+"Repayment"]: noDotAndInteger(calcInput.repayment),
            [prefix+"ProvisiePercentage"]: +calcInput.provisiePercentage,
            kentekenInLossing: repaymentDetails.kentekenInLossing,
            bankInLossing: repaymentDetails.bankInLossing,
            contractInLossing: repaymentDetails.contractInLossing,
        }

        var resp = await patchDeal(props.dealID, bankData, false);
        if(resp==="SUCCESS") {
            var chosenBank = getFullBankNameFromPrefix(prefix);
            createActivity("Deal", "update", {dealID: props.dealID}, bankData, chosenBank);
            props.onUpdate();
            setCalcDataChanged(false);
        }
    }
 
    /* validation */
    const [calcFeedback, setCalcFeedback] = useState({
        formIsValid: false,
        show: false,
        title: "",
        content: ""
    });

    const [calcDataChanged, setCalcDataChanged] = useState(false);
    const [contractLock, setContractLock] = useState(true);
    const [copied, setCopied] = useState(false);
    const [bankResp, setBankResp] = useState({
        status: props.status || "",
        contractNumber: props.contractNumber || ""
    });
    const [administrationCost, setAdministrationCost] = useState(props.administrationCost ? intTo2DecStr(props.administrationCost) : "");

    const [repaymentDetails, setRepaymentDetails] = useState({
        kentekenInLossing: calcDetail?.kentekenInLossing || props.kentekenInLossing || "",
        bankInLossing: calcDetail?.bankInLossing || props.bankInLossing || "",
        contractInLossing: calcDetail?.contractInLossing || props.contractInLossing || ""
    });
    const { isLoading, isRequested, responseData, getBankStatus, postBankData } = useBankApi();

    // const nextHandler = () => {
    //     if(calcInput.repayment) setRepaymentDetails(prevState => ({...prevState, modalOpen: true}));
    // }

    const offerHandler = (actionType) => {
        props.onClose();
        if(actionType==="download") props.onDownloadPdf();
        if(actionType==="send") props.onSendPdf();
    }

    const copyToClipboardHandler = () => {
        navigator.clipboard.writeText(props.offerCustomer);
        setCopied(true)
        setTimeout(function() {
            setCopied(false);
        }, 2500);
    }

    const fieldChangeHandler = (evt, state) => {
        var { value, name } = evt.target;
        switch(true) {
            case /administrationCost/.test(state):
                value = value.replace(".", ",");
                setAdministrationCost(value);
                break;
            case /bankResp/.test(state):
                setBankResp(prevState => {
                    return {
                        ...prevState,
                        [name]: value
                    }
                });
                break;
            case /repayment/.test(state):
                setRepaymentDetails(prevState => ({...prevState, [evt.target.name]: evt.target.value}));
                break;
            default:
                break;
        }
    }

    var calculationValid = calcFeedback?.formIsValid && calcInput.provisiePercentage && calcInput.interest;
    var calculationValidForSubmitting = calcInput.provisiePercentage && calcInput.interest;

    var calcValidForUseInProposal = props?.bankDetail?.provisiePercentage && calcInput?.interest && !isNaN(calcInput.commissionAmountDealer) && !isNaN(calcInput.commissionAmountSLD);

    const submitCalcHandler = (skipRepayment=false) => {
        if(!skipRepayment && (calcInput?.repayment && calcInput?.repayment!=="0") && !repaymentDetails?.isConfirmed) {          
            setRepaymentDetails(prevState => ({...prevState, modalOpen: true}));
            return;
        } else {
            dispatch(uiActions.hideBackdrop());
        }

        var vat = (calcInput?.vat ? noDotAndInteger(calcInput.vat) : 0);
        var amountpurchase = (calcInput?.amountpurchase ? noDotAndInteger(calcInput.amountpurchase) : 0);
        var amountpurchaseExclVAT = (calcInput?.amountpurchaseExclVAT ? noDotAndInteger(calcInput.amountpurchaseExclVAT) : 0);

        if(/marge/i.test(calcInput.typeOfDeal)) {
            vat = 0;
            amountpurchaseExclVAT = amountpurchase;
        }

        var calcObj = transformCalcObjToCorrectFormat({
            ...calcInput,
            vat,
            amountpurchase,
            amountpurchaseExclVAT
        });

        if(Storage) sessionStorage.setItem("calcDetail", JSON.stringify(calcObj));
        dispatch(dealActions.setCalcDetail(calcObj));
        // changeStepHandler("../klantgegevens");
        navigate("../klantgegevens");
    }

    useEffect(() => {
        if(Storage && sessionStorage.getItem("calcDetail")) {
            var amountpurchase = JSON.parse(sessionStorage.getItem("calcDetail")).amountpurchase;
            if(amountpurchase) loadCalculationFromStorageData();
        }
        if(props.inModal) {
            loadCalculationFromStorageData();
        }
    }, []);
    
    const confirmRepaymentDetailsHandler = () => {
       dispatch(dealActions.setRepaymentDetail({...repaymentDetails, modalOpen: false}));
       submitCalcHandler(true);
    }
    
    const validateCalculation = () => {
        let valid = true;
        var errMessage = {};
        const { leaseAmount, amountpurchase, amountpurchaseExclVAT, provisiePercentage, finalpayment, finalpaymentMax } = valuesToNoDot({leaseAmount: calcInput.leaseAmount, amountpurchase: calcInput.amountpurchase, amountpurchaseExclVAT: calcInput.amountpurchaseExclVAT, provisiePercentage: calcInput.provisiePercentage, finalpayment: calcInput.finalpayment, finalpaymentMax: calcInput.finalpaymentMax});

        if(!provisiePercentage) {
            valid = false;
            errMessage.title = "Vul de aanvullende instelling in";
            errMessage.content = "Vul de aanvullende instelling in";
        }
        if( (leaseAmount>amountpurchase) || (calcInput.typeOfDeal?.toLowerCase()==="btw" && leaseAmount>amountpurchaseExclVAT)) {
            valid = false;
            errMessage.title = "Leasebedrag te hoog";
            errMessage.content = ["Het leasebedrag kan niet hoger zijn dan de aanschafwaarde"+(calcInput.typeOfDeal?.toLowerCase()==="btw" ? " Excl. BTW." : ""), <br />, "Verhoog de aanbetaling of de inruil."]
        }
        if( (calcInput.typeOfDeal?.toLowerCase()==="btw") && (amountpurchaseExclVAT>amountpurchase) ) {
            valid = false;
            errMessage.title = "Aanschaf Excl. te hoog";
            errMessage.content = "Aanschaf Excl.BTW mag niet hoger zijn dan Aanschaf Incl.BTW";
        }
        if(leaseAmount<5000) {
            valid = false;
            errMessage.title = "Leasebedrag te laag";
            errMessage.content = "Leasebedrag kan niet lager zijn dan \u20AC 5.000,00";
        }
        if( (finalpayment && finalpaymentMax) && (finalpayment>finalpaymentMax) ) {
            valid = false;
            errMessage.title = "Slottermijn te hoog";
            errMessage.content = ["De opgegeven slottermijn is hoger dan toegestaan.", <br />, "De maximale slottermijn is \u20AC "+formatThousandNotation(finalpaymentMax)];
        }
        if(!valid) {      
            setCalcFeedback(prevState => {
                return {
                    ...prevState,
                    formIsValid: false,
                    show: true,
                    title: errMessage.title,
                    content: errMessage.content
                }
            });      
        } else {
            setCalcFeedback({formIsValid: true, show: false, title: "", content: ""});
        }
    };
    
    const [reAssessment, setReAssessment] = useState(false); // herbeoordeling

    var {
        duration: duration_v,
        downpayment: downpayment_v,
        monthlyamount: monthlyamount_v,
        typeOfDeal: typeOfDeal_v,
        leaseAmount: leaseAmount_v,
        amountpurchase: amountpurchase_v,
        amountpurchaseExclVAT: amountpurchaseExclVAT_v,
        provisiePercentage: provisiePercentage_v,
        finalpayment: finalpayment_v,
        finalpaymentMax: finalpaymentMax_v,
        tradeInAmount: tradeInAmount_v,
        repayment: repayment_v
    } = calcInput;
 
    useEffect(() => {
        if(!leaseAmount_v) return;
        validateCalculation();
    }, [typeOfDeal_v, leaseAmount_v, amountpurchase_v, amountpurchaseExclVAT_v, provisiePercentage_v, finalpayment_v, finalpaymentMax_v, tradeInAmount_v, repayment_v, downpayment_v]);

    const checkForReAssessment = useCallback(() => {
        var re = false;
        if(noDotAndInteger(finalpayment_v)>props.finalpayment) re = true;
        if(duration_v>props.duration) re = true; 
        if( (downpayment_v ? noDotAndInteger(downpayment_v) : 0) < props.downpayment) re = true;
        if( (noDotAndInteger(amountpurchase_v) > props.amountpurchase) && (noDotAndInteger(leaseAmount_v) <= props.leaseAmount) ) re = true;
        if( (noDotAndInteger(tradeInAmount_v) < props.tradeInAmount)) re = true;
        if( (noDotAndInteger(repayment_v) > props.repayment )) re = true;   
        if( (noDotAndInteger(leaseAmount_v) > props.leaseAmount )) re = true;   
        setReAssessment(re);
    },[finalpayment_v, duration_v, downpayment_v, amountpurchase_v, tradeInAmount_v, repayment_v, leaseAmount_v]);

    useEffect(() => {
        checkForReAssessment();
    }, [monthlyamount_v, checkForReAssessment]);

    var chosenBank = getBankSelectOptions(calcInput.chosenBank, props.calculatorGoal);
    var bankRegEx = new RegExp(props.calcsrc?.replace(/_.*/, "")) || null; // replace _.* is used to replace for example hi_\d to hi
    var possibleBanks = (props.calculatorGoal==="bank" || props.quickCalc) ? chosenBank.filter(item => bankRegEx.test(item.value)) : chosenBank.filter(item => item.value===calcInput.chosenBank);   
    var bankSpecificCommissions = bankSettings?.filter(item => item.shortcode===calcInput.chosenBank)[0]?.provisie_choices;

    {/* For Users (not Admin) show only interest endsWith .99% for Hi */}
    if(!role.includes("Admin") && /^hi/.test(calcInput.chosenBank)) bankSpecificCommissions = bankSpecificCommissions.filter(item => item.interest.toString().endsWith(".99"));

    var commissionValueIndex = bankSpecificCommissions?.map((item, index) => ({...item, index: index})); // get the array index of the commission/interest
     // in case bank=hi_2  and companyAge>=6 months, get interestWithCondition
     if(/hi_2/.test(calcInput.chosenBank) && parseInt(calcInput.companyAge)>=6) commissionValueIndex = bankSpecificCommissions?.map((item, index) => ({...item, interest: item.interestWithCondition, index: index}));

     if(/hi_3/.test(calcInput.chosenBank)) {
        commissionValueIndex = bankSpecificCommissions?.map((item, index) => {
            var interest = item?.interest;
            if(parseInt(calcInput?.leaseAmount)>=40000) interest = interest - 1; // subtract 1 percentage, when leaseAmount is gte 40000
            if(parseInt(calcInput?.companyAge)>=6) interest = interest - 1.5; // subtract 1.5 percentage, when companyAge is gte 6 months
            interest = Math.round(interest * 100) / 100; // this is required, else the output is: 15.989999999999998...
            return {...item, interest: interest, index: index}
        });
     }

     // if chosenBank = AL and duration <= 24, max prov = 2
    if(/^al/.test(calcInput.chosenBank) && +calcInput.duration<=24) bankSpecificCommissions = bankSpecificCommissions?.filter(item => item.prov<=2);

    if(/^hi/.test(calcInput.chosenBank)) {
        commissionValueIndex = commissionValueIndex.filter(item=>item.prov===calcInput.provisiePercentage&&item.interest===calcInput.interest)[0]?.index;
    } else {
        commissionValueIndex = commissionValueIndex.filter(item=>item.prov===calcInput.provisiePercentage)[0]?.index;
    }

    const prevStepHandler = () => {
        var calcObj = transformCalcObjToCorrectFormat(calcInput);
        dispatch(dealActions.setCalcDetail(calcObj));
        if(Storage) sessionStorage.setItem("calcDetail", JSON.stringify(calcObj));
        navigate("/financiering/financial-lease/btw-marge");
    }

    var bedrijfAndBtwAuto = (/bedrijf/i.test(calcInput.vehicleType) && /btw/i.test(calcInput.typeOfDeal));

    return (
        <>
        <div className="container mx-auto max-w-screen-xl px-5 xl:px-0">
        {!props.disableTitle && <h1>Bereken maandbedrag</h1>}
        <form onChange={() => setCalcDataChanged(true)} onSubmit={evt => evt.preventDefault()} className="grid md:grid-cols-2 gap-10 xmt-10">
            <div className="flex flex-col max-w-xl gap-3">
                {calcFeedback?.show && <div className="border-2 bg-[#f8d7da] border-[#f5c6cb] color-[#721c24] p-3 text-sm">{calcFeedback?.content}</div>}
                {(role && role.includes("Admin")) && (
                    <>
                        {/* <Select name="vehicleType" label="Voertuigsoort" disableFirst={true} onChange={changeVehicleType} value={calcInput.vehicleType} options={[{label: "Personenauto", value: "AUTO"},{label: "Bedrijfsauto", value: "BEDRIJF"}]}></Select> */}
                        <Select name="chosenBank" label="Bank" disableFirst={true} onChange={changeBank} value={calcInput.chosenBank} options={possibleBanks}></Select>
                    </>
                )}
                <Input valuta="euro" onBlur={setTouchedField} onChange={bedrijfAndBtwAuto ? null : changeCalcField} backgroundColor={`${bedrijfAndBtwAuto ? "#ccc" : ""}`} readOnly={bedrijfAndBtwAuto} inputMode="numeric" placeholder={/btw/i.test(calcInput.typeOfDeal) ? "Aanschafwaarde Incl. BTW" : "Aanschafwaarde"} name="amountpurchase" value={formatThousandNotation(calcInput.amountpurchase, false)} />
                {/btw/i.test(calcInput.typeOfDeal) && <>
                    <Input name="vat" onChange={changeCalcField} backgroundColor="#ccc" valuta="euro" readOnly placeholder="BTW-bedrag" inputMode="numeric" value={formatThousandNotation(calcInput.vat, false)} help={["Het is niet mogelijk de BTW mee te nemen in de Financial Lease.", <div className="h-2">&nbsp;</div>, "De klant dient deze van tevoren bij u aan te betalen. Is het niet mogelijk om de BTW in één keer te voldoen? Geen enkel probleem! Wij kunnen eventueel de mogelijkheden bekijken voor een kortlopende financiering. Neem hiervoor contact op met ", <strong>SLD</strong>]} />
                    <Input onBlur={setTouchedField} name="amountpurchaseExclVAT" valuta="euro" inputMode="numeric" placeholder="Aanschaf Excl. BTW" onChange={changeCalcField} value={formatThousandNotation(calcInput.amountpurchaseExclVAT, false)} />
                </>}
                <Input name="downpayment" placeholder="Aanbetaling" valuta="euro" onChange={changeCalcField} inputMode="numeric" value={formatThousandNotation(calcInput.downpayment, false)} help="Wilt de klant een extra aanbetaling doen naast de (eventuele) BTW? U kunt hier het gewenste bedrag invullen. Wij zullen dit in mindering brengen van het leasebedrag."/>
                <Input name="tradeInAmount" placeholder="Inruil" valuta="euro" inputMode="numeric" value={formatThousandNotation(calcInput.tradeInAmount, false)} onChange={changeCalcField} help={["Heeft de klant een inruilauto? Vul hier de waarde in! Dit zal dan worden gebruikt als extra aanbetaling op de financiering.", <div className="h-2">&nbsp;</div>, "Wilt de klant dit niet meenemen in de financiering? Laat het veld dan leeg. Het is dan ook noodzakelijk om hier een losse factuur van te maken."]} />
                <Input name="repayment" placeholder="Inlossing openstaand saldo" valuta="euro" onChange={changeCalcField} value={formatThousandNotation(calcInput.repayment, false)} />
                <Input name="leaseAmount" placeholder="Leasebedrag" valuta="euro" inputMode="numeric" readOnly={true} value={formatThousandNotation(calcInput.leaseAmount, false)} help={["Uw leasebedrag wordt berekend als volgt:", <div className="h-2">&nbsp;</div>,"Aanschaf Excl.BTW", <br />, "Aanbetaling -", <br />, "Inruil -", <br />, "Eventuele inlossing +"]} />
                {( (calcInput.leaseAmount>0) && (calcInput.finalpaymentMax) && (+calcInput.finalpayment > +calcInput.finalpaymentMax) ) && <div className="text-xs">Maximale slottermijn is: &euro; {formatThousandNotation(calcInput.finalpaymentMax, false)}</div>}
                <InputRange thousandNotation={true} enableManualInput={true} suffix=" maanden" label="Looptijd" name="duration" min="12" max={calcInput.durationMax} step="6" value={calcInput.duration} onChange={changeCalcField} />
                <Input name="finalpayment" placeholder="Slottermijn" valuta="euro" onChange={changeCalcField} value={formatThousandNotation(calcInput.finalpayment, false)} />
                {(role.includes("Admin") && props.currentCalculation) && 
                <Input valuta="euro" name="administrationCost" placeholder="Handelingsfee" value={administrationCost} onChange={evt => fieldChangeHandler(evt, "administrationCost")} />
                }
                
                {calcInput.leaseAmount>0 &&
                <details className="xborder xborder-slate-300 cursor-pointer text-slate-500" open={role && role.includes("Admin") && true}>
                    <summary className="p-3 select-none">Aanvullende instellingen</summary>
                    {/* impossible props, cannot be chosen by actua, only by sld admin */}

                    <InputCommission
                    calculatorGoal={props.calculatorGoal}
                    impossibleInterest={calcInput.interest}
                    impossibleMonthly={calcInput.monthlyamount}
                    impossibleCommission={calcInput.commissionAmountDealer}
                    name="provisiePercentage"
                    chosenBank={calcInput.chosenBank}
                    leaseAmount={calcInput.leaseAmount} suffix=" %"
                    provisie_choices={bankSpecificCommissions} label="Provisie"
                    min="0" max={bankSpecificCommissions?.length - 1}
                    value={commissionValueIndex}
                    onChange={(evt) => {
                        changeInterestFromRange(evt, bankSpecificCommissions);
                        setCalcDataChanged(true);
                    }} />
                    <select id="rente" className={`hidden border border-slate-300 rounded-[4px] p-3 max-w-full`} name="interest" defaultValue={calcInput.provisiePercentage} onChange={changeInterest}>
                        <option value=""></option>
                    {bankSettings?.length && bankSettings?.filter(item => item.shortcode===calcInput.chosenBank)[0].provisie_choices.map(({prov, interest, basicInterest, nominalInterest, commissionAmountDealer, monthlyamount}, index) => {
                            var randomId = calcInput.chosenBank+prov+interest+index;
                            return <option data-key={'pp'+randomId} key={'pp'+randomId} data-pp={prov} data-interest={interest} data-basic-interest={basicInterest} data-nominal-interest={nominalInterest} value={prov}>klantrente: {interest}% -   provisiebedrag: &euro; {formattedPMT_v2(commissionAmountDealer)} - per maand &euro; {formattedPMT_v2(monthlyamount)}</option>
                        })} 
                    </select>
                    {(role.includes("Admin") && typeof props.currentCalculation) && 
                    <div className="border-2 bg-[#d1ecf1] border-[#bee5eb] color-[#0c5460] mt-5 p-4">
                        <div className="font-semibold">Admin info</div>
                        <div className="">Totale provisie: &euro; {calcInput.commissionTotal ? formattedPMT_v2(calcInput.commissionTotal): ''}</div>
                        <div className="">Provisie dealer: &euro; {calcInput.commissionAmountDealer!=='NaN' ? formattedPMT_v2(calcInput.commissionAmountDealer) : ''}</div>
                        <div className="">Provisie rest: &euro; {calcInput.commissionAmountSLD!=='NaN' ? formattedPMT_v2(calcInput.commissionAmountSLD) : ''}</div>
                        <div className="">dealerCategory: {calcInput.dealerCategory ? calcInput.dealerCategory : ''}</div>
                    </div>
                    }
                </details>
                }

                {(role && role.includes("Admin") && props.dealID) &&
                <div className="flex flex-col gap-y-3">
                    <Input name="contractInLossing" placeholder="Contractnummer inlossing" value={repaymentDetails.contractInLossing} onChange={evt => fieldChangeHandler(evt, "repayment")} />
                    <Input name="kentekenInLossing" placeholder="Kenteken inlossing" value={repaymentDetails.kentekenInLossing} onChange={evt => fieldChangeHandler(evt, "repayment")} />
                    <Input name="bankInLossing" placeholder="Bank inlossing" value={repaymentDetails.bankInLossing} onChange={evt => fieldChangeHandler(evt, "repayment")} />
                </div>
                }
            </div>

            <div className="flex flex-col max-w-sm">
                {debug && <CalcDebug calcInput={calcInput} bankSettings={bankSettings} />}

                <CalcSummary calcInput={calcInput} />

                {(role.includes("Admin") && !props.calculatorGoal && props.dealID) &&
                <div className="flex flex-col justify-start gap-y-3 items-start text-start my-10">
                    {!props.ondervoorbehoud && <Button onClick={() => offerHandler('send')}>Offerte versturen</Button>}
                    <Button className="btn-secondary flex flex-col" type="button" onClick={() => offerHandler('download')}><span className="">Download offerte </span><span className="inline-block text-xs">{props.ondervoorbehoud && '(Onder voorbehoud)'}</span></Button>

                    {!props.ondervoorbehoud &&
                    <div className="flex flex-row items-center w-full cursor-pointer mt-5 text-sm gap-x-2 " onClick={copyToClipboardHandler}>
                        <div className="flex flex-row items-center border-2 border-transparent py-1 gap-x-2">
                            <span className="underline">Offerte link</span>
                            <span className="fa fa-clipboard"></span>
                        </div>
                        {copied && <div className={"px-3 py-1 transition-all border-2 bg-[#d4edda] border-[#c3e6cb] color-[#155724]"}>Gekopieerd naar het klembord</div>}
                    </div>
                    }
                </div>
                }
                {props.currentCalculation && props.dealID && (role && role.includes("Admin") && props.calculatorGoal==="bank") &&
                <>
                    {/(df|hi)/.test(props.calcsrc) &&
                    <div className="flex flex-col gap-y-3 mb-3">
                        <div className="text-2xl font-semibold mt-5">Lopende financiering:</div>
                        {(/^hi/.test(props.calcsrc)) && <Input readOnly={contractLock} onLockToggle={() => setContractLock(prevState => !prevState)} showLock={true} locked={contractLock} placeholder="Contractnummer" type="text" name="contractNumber" value={bankResp.contractNumber} onChange={evt => fieldChangeHandler(evt, "bankResp")} />}
                        {(props.calcsrc==="df" || /^df_/.test(props.calcsrc)) && 
                        <div className="grid grid-cols-2">
                            <div>Contractnummer</div><div>{responseData.contractNumber}</div>
                        </div>}
                        {(!/(hi|df)/.test(props.calcsrc) && props.contractNumber) && <div className="grid grid-cols-2"><div>Contractnummer</div><div>{props.contractNumber}</div></div>}
                        <>        
                        <div className="flex flex-col bg-slate-100 p-3 rounded-[4px] text-sm relative max-h-[280px] overflow-y-auto overflow-x-hidden">
                            <div className="grid grid-cols-5">
                                <div className={`${(responseData.message || responseData.reason?.length>0) && 'mb-2'}`}>Status</div><div className={`col-span-4 font-semibold flex flex-row justify-between ${(responseData.message || responseData.reason?.length>0) && 'mb-2'}`}><span>{responseData.status}</span>{isLoading ? <Loader /> : <span title="Status vernieuwen" onClick={() => getBankStatus(props.calcsrc, props.dealID)} className="fa fa-arrows-rotate cursor-pointer text-[#E50823]"></span>}</div>
                                {responseData.message && <><div className={`${responseData.reason?.length>0 && 'mb-2'}`}>Bericht</div><div className={`col-span-4 break-words font-semibold ${responseData.reason?.length>0 && 'mb-2'}`}>{responseData.message}</div></>}
                                {responseData.reason?.length>0 && <><div className="">Reden</div><div className="col-span-4 font-semibold flex flex-col">{responseData.reason.map((item, index) => {
                                    return (<span key={"reason"+index}>{item}</span>)
                                })}</div></>}
                                {responseData?.rejectReason && <><div className=""></div><div className="col-span-4 font-semibold mt-5">{responseData?.rejectReason}</div></>}
                                {responseData?.creditAppStatus?.ProductType && (
                                    <div className="grid grid-cols-2 col-span-5 mt-5">
                                        <div className="col-span-2 mb-1 font-semibold">Financieringsvoorstel</div>
                                        <span className="">Aanschafprijs Incl.BTW</span><span className="text-end">{responseData?.creditAppStatus?.GrossInvestment}</span>
                                        <span className="">Aanbetaling (incl. BTW)</span><span className="text-end">{responseData?.creditAppStatus?.UpfrontAmount}</span>
                                        <span className="">Leasebedrag</span><span className="text-end">{responseData?.creditAppStatus?.CreditAmount}</span>
                                        <span className="">Looptijd</span><span className="text-end">{responseData?.creditAppStatus?.Duration}</span>
                                        {/* <span className="text-xs">CreditCompensation</span><span>{responseData?.creditAppStatus?.CreditCompensation}</span> */}
                                        <span className="">Slottermijn</span><span className="text-end">{responseData?.creditAppStatus?.BalloonPayment}</span>
                                        <span className="">Inruilprijs</span><span className="text-end">{responseData?.creditAppStatus?.TradeInValue}</span>
                                        <span className="">Maandbedrag</span><span className="text-end">{responseData?.creditAppStatus?.TermAmount}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        </>
                    </div>
                    }
                    {/(al|fi)/.test(props.calcsrc) && (
                        <div className="flex flex-col gap-y-3 my-3">
                            <Select placeholder="Bank status" set="bankStatus" label="Bank status" name="status" value={bankResp.status} onChange={evt => fieldChangeHandler(evt, "bankResp")} />
                        </div>
                    )}

                    <div className="flex flex-col gap-y-3 mb-3">
                        {calcDataChanged
                        ?
                        <>
                        <Button className="primary !max-w-xs bg-gradient" onClick={() => updateBankData(props.prefix)}>Opslaan</Button>
                        {/(df|hi)/.test(props.calcsrc) && <Button className="primary !max-w-xs opacity-50" onClick={() => dispatch(uiActions.setAlert({message: "Sla de wijzigingen eerst op!", type: "danger", time: 5000}))}>{!props.requestNumber ? "Aanvraag" : "Herbeoordeling"} indienen</Button>}
                        <Button className="primary !max-w-xs !bg-[green] opacity-50" onClick={() => dispatch(uiActions.setAlert({message: "Sla de wijzigingen eerst op!", type: "danger", time: 5000}))}>Voorstel gebruiken in offerte</Button>
                        </>
                        :
                        <>
                        <Button className="primary !max-w-xs bg-gradient opacity-50" onClick={() => dispatch(uiActions.setAlert({message: "Er zijn geen wijzigingen gedaan. Je hoeft niet op te slaan.", type: "danger", time: 5000}))}>Opslaan</Button>
                        {(/(df|hi)/.test(props.calcsrc) && calculationValidForSubmitting) && (
                            <>
                            {!props.contractNumber ?
                            <Button disabled={isRequested} className={`primary !max-w-xs  ${isRequested && 'opacity-50'}`} onClick={() => postBankData(props.calcsrc, props.dealID, props.requestNumber)}>Aanvraag indienen</Button>
                            :
                            <>
                            {!bankResp.contractNumber 
                            ?
                            <>
                            <Button className="primary !max-w-xs opacity-50" onClick={() => dispatch(uiActions.setAlert({message: "Je kunt geen herbeoordeling indienen, zonder een contractnummer op te geven.", type: "danger", time: 5000}))}>Herbeoordeling indienen</Button>
                            </>
                            :
                            <Button disabled={isRequested} className={`primary !max-w-xs ${isRequested && 'opacity-50'}`} onClick={() => postBankData(props.calcsrc, props.dealID, props.requestNumber)}>Herbeoordeling indienen</Button>
                            }
                            </>
                            }
                            </>   
                        )}
                        {calcValidForUseInProposal && <Button className="primary !max-w-xs !bg-[green]" onClick={() => updateFinanceHandler(false, true)}>Voorstel gebruiken in offerte</Button>} 
                        </>
                        }
                    </div>
                    {!calculationValid || (props.calculatorGoal==="bank" && !calcValidForUseInProposal) && <div className="mt-5">De berekening is niet compleet.</div>}
                </>
                }

                {/* Eerdere berekening */}
                <EarlierCalculated onUpdateFinance={updateFinanceHandler} onClose={props.onClose} calcFeedback={calcFeedback} reAssessment={reAssessment}
                calcInput={calcInput} relatedID={props.relatedID} currentCalculation={props.currentCalculation} calculatorGoal={props.calculatorGoal}
                typeOfDeal={props.typeOfDeal} commissionAmountDealer={props.commissionAmountDealer} leaseAmount={props.leaseAmount}
                />
            </div> 
        </form>
        </div>

        <Modal title="Klant heeft lopend contract" show={repaymentDetails?.modalOpen} onClose={() => setRepaymentDetails(prevState => ({...prevState, modalOpen: false}))}>
            <div>U heeft aangegeven dat er een openstaand saldo actief is op de inruilauto. Vul de volgende gegevens in:</div>
            <Input name="kentekenInLossing" placeholder="Kenteken" value={repaymentDetails?.kentekenInLossing} onChange={evt => setRepaymentDetails(prevState => ({...prevState, [evt.target.name]: evt.target.value}))} />
            <Input name="contractInLossing" placeholder="Contractnummer" value={repaymentDetails?.contractInLossing} onChange={evt => setRepaymentDetails(prevState => ({...prevState, [evt.target.name]: evt.target.value}))} />
            <Input name="bankInLossing" placeholder="Bank" value={repaymentDetails?.bankInLossing} onChange={evt => setRepaymentDetails(prevState => ({...prevState, [evt.target.name]: evt.target.value}))} />
            <Button type="button" className="btn-primary" onClick={confirmRepaymentDetailsHandler}>Bevestigen</Button>
        </Modal>

        {(!props.inModal) && 
        <div className={`flex flex-row container mx-auto max-w-screen-xl mt-5 justify-between ${props.hidePrevNext ? "hidden" : ""}`}>
            <Button type="button" onClick={prevStepHandler} className="flex flex-row disabled:scale-100 hover:scale-105 items-center">
                <div className="fa fa-chevron-left mr-2"></div><span>Vorige</span>
            </Button>
            <Button type="button" className="btn-primary flex flex-row disabled:scale-100 hover:scale-105 items-center" onClick={() => submitCalcHandler()} disabled={!calcFeedback?.formIsValid}>
                <span>Volgende</span><div className="fa fa-chevron-right ml-2"></div>
            </Button>     
        </div>
        }

        {/* <PrevNext onShowPopup={() => setRepaymentDetails(prevState => ({...prevState, modalOpen: true}))} showInlossingModal={calcInput.repayment && !repaymentDetails.isConfirmed} inlossingConfirmed={repaymentDetails.isConfirmed} nextDisabled={!calcFeedback?.formIsValid} /> */}
   </>
    )
}

export default CalculatorV3;