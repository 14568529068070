import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { calcRealCommissionPerc } from "./helpers";
import { calcAgeInMonths, formattedPMT_v2 } from "lib/calc";
import useAuth from "hooks/use-auth";
import useHuisstijl from "hooks/use-huisstijl";

const StyledInputCommission = styled.input`
height: 34px;
-webkit-appearance: none;
width: 100%;
background-color: transparent;
&:focus {
    outline: none;
}
&::-webkit-slider-runnable-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000;
    border-radius: 1px;
    border: 0px solid #010101;
    background-color: ${props => props.$primaryColor ? props.$primaryColor : 'black'}

}
&::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000031;
    border: 1px solid #00001E;
    height: 26px;
    width: 26px;
    border-radius: 15px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
}

&:focus::-webkit-slider-runnable-track {
    background-color: ${props => props.$primaryColor ? props.$primaryColor : 'black'}

}
&::-moz-range-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000;
    background-color: ${props => props.$primaryColor ? props.$primaryColor : 'black'};

    border-radius: 1px;
    border: 0px solid #010101;
}
&::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000031;
    border: 1px solid #00001E;
    height: 26px;
    width: 26px;
    border-radius: 15px;
    /* background: #FFFFFF; */
    cursor: pointer;
}
&::-ms-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
&::-ms-fill-lower,
&::-ms-fill-upper {
    background-color: ${props => props.$primaryColor ? props.$primaryColor : 'black'};
    border: 0px solid #010101;
    border-radius: 2px;
    box-shadow: 1px 1px 1px #000000;
}
&::-ms-thumb {
    margin-top: 1px;
    box-shadow: 1px 1px 1px #000031;
    border: 1px solid #00001E;
    height: 26px;
    width: 26px;
    border-radius: 15px;
    cursor: pointer;
}
&:focus::-ms-fill-lower,
&:focus::-ms-fill-upper {
    background-color: ${props => props.$primaryColor ? props.$primaryColor : 'black'};
}
`;

const InputCommission = React.forwardRef((props, ref) => {
    const { role } = useAuth();

    const [init, setInit] = useState(true);
    const [inputValue, setInputValue] = useState(props?.value);

    useEffect(() => {
        if(init) return;
        props.onChange({target: {name: props.name, value: inputValue}});
    }, [inputValue]);

    useEffect(() => {
        setInit(false);
    }, []);

    var indexOfMostPopular = 0;
    if(props.chosenBank==="df" || /^df_/.test(props.chosenBank)) indexOfMostPopular = 3;
    if(/hi_/.test(props.chosenBank)) indexOfMostPopular = props?.provisie_choices?.findIndex(item => item?.interest===12.99); 
    if(props.chosenBank==="fi") indexOfMostPopular = 5;
    if(/^al/.test(props.chosenBank)) indexOfMostPopular = 2;

    // var commissionAmountDealer = props.provisie_choices[inputValue]?.commissionAmountDealer || props.impossibleCommission
    var interest = props.provisie_choices[inputValue]?.interest || (!props.calculatorGoal ? props.impossibleInterest : "");
    var monthlyamount = props.provisie_choices[inputValue]?.monthlyamount || (!props.calculatorGoal ? props.impossibleMonthly : "");

    return (
        <>
            <div className="border border-slate-300 rounded-xl bg-white pt-1 pb- px-3 relative flex flex-col">

                <div className="flex flex-row justify-between">
                    <label className="text-xs xabsolute top-0">{props.label}</label>
                    {/* <span className="">&euro; {formattedPMT_v2(props.provisie_choices[inputValue]?.commissionAmountDealer)} ({calcRealCommissionPerc(props.provisie_choices[inputValue]?.commissionAmountDealer, props.leaseAmount)}%)</span> */}
                
                    <div className="flex flex-row justify-between gap-x-5 xmt-5">
                    {/* <span>{props.provisie_choices[inputValue]?.prov}%</span> */}
                    <div className="flex flex-row gap-x-1 text-center">
                        <div className="flex flex-col bxg-slate-100 px-3">
                            <span className="text-sm">Klantrente</span>
                            <span className="text-black">{interest}%</span>
                        </div>
                        <div className="flex flex-col xbg-slate-100 px-3">
                            <span className="text-sm">Per maand</span>
                            <span className=" text-black">&euro; {formattedPMT_v2(monthlyamount)}</span>
                        </div>
                    </div>

                    {/* <span>Klantrente {props.provisie_choices[inputValue]?.interest}%</span>
                    <div>p/mnd <span className="inline-block">&euro; {formattedPMT_v2(props.provisie_choices[inputValue]?.monthlyamount)}</span></div> */}
                    {/* <span>Commisie &euro; {formattedPMT_v2(props.provisie_choices[inputValue]?.commissionAmountDealer)} ({props.provisie_choices[inputValue]?.prov}%)</span> */}
                    </div>

                </div>

                {/* <div className="absolute top-0 right-[39%]"> */}
                    {/* <span className="text-xs font-bold text-black p-1" style={{xbackgroundColor: "rgba(28,255,20,1)"}}>Meest gekozen</span> */}
                    {/* <div className="flex flex-row gap-x-1 items-center"> */}
                        {/* <span className="text-xs font-bold text-black p-1" style={{xbackgroundColor: "rgba(28,255,20,1)"}}>Meest gekozen</span> */}
                        {/* <span className="fa fa-arrow-down !text-md"></span> */}
                        {/* <span className={`!text-md text-black fa fa-arrow-down relative lg:right-[2px]`}></span> */}
                        {/* <span className={`!text-md fa ${props.provisie_choices[inputValue]?.interest=="12.99" ? "fa-check" : "fa-arrow-down"}`}></span> */}
                        {/* <span className="fa fa-hand-back-point-down !text-md"></span> */}
                    {/* </div> */}
                {/* </div> */}
                
                <StyledInputCommission $primaryColor="#000000" type="range" name={props.name} ref={ref} value={inputValue} min="0" max={props.max} step={props.step} onChange={props.forwardOnChange ? props.onChange : evt => setInputValue(evt.target.value)} />          
                {/* <div className="flex flex-row justify-between px-2.5 w-full -top-2 relative select-none pointer-events-none">{props.provisie_choices.map((item, index) => <div className="block w-[3px] h-[5px] bg-blue"></div>)}</div> */}
                <div className={`flex flex-col text-start mt-3`}>
                    <span className="font-semibold select-none">Hoeveel wil je verdienen?</span>


                    {/* For Users (not Admin) show only interest endsWith .99% for Hi */}
                    {props.provisie_choices.map((item, index) => (<div key={"index"+index} className={`${(!role.includes("Admin") && !/.99/.test(item.interest) && /^hi/.test(props.chosenBank)) ? "hidden" : ""} grid grid-cols-3 select-none border-t border-slate-200 py-1 px-3 hover:bg-black hover:text-white ${index==inputValue && "bg-black text-white"}`} onClick={() => setInputValue(index)}>
                        <span>&euro; {formattedPMT_v2(item?.commissionAmountDealer)}</span>
                        {index===indexOfMostPopular ? <span className="flex items-center bg-green py-0 px-3 w-fit text-white text-center x-rotate-2 text-xs font-semibold">Meest gekozen!</span> : <span></span>}
                        <span className="text-end">({calcRealCommissionPerc(item?.commissionAmountDealer, props.leaseAmount)}%)</span>
                    </div>))}
                </div>
            </div>
        </>
    )
});

export default InputCommission;