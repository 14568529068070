export const BANK_SETTINGS = [
    {
        shortcode: "df_3",
        name: "Dutch Finance (vanaf 14 okt. 24)",
        paymentTime: 0,
        provisiePercentage: 20,
        provisie_choices: [
            {prov: 10, interest: 8.99},
            {prov: 15, interest: 9.99},
            {prov: 17.5, interest: 10.99},
            {prov: 20, interest: 11.99},
            {prov: 22.5, interest: 12.99},
            {prov: 27.5, interest: 13.99},
        ],
    },
    {
        shortcode: "df_2",
        name: "Dutch Finance (t/m 14 okt. 24)",
        paymentTime: 0,
        provisiePercentage: 17.5,
        provisie_choices: [
            {prov: 10, interest: 8.99},
            {prov: 12.5, interest: 9.99},
            {prov: 15, interest: 10.99},
            {prov: 17.5, interest: 11.99},
            {prov: 20, interest: 12.99},
            {prov: 25, interest: 13.99},
        ],
    },
    {
        shortcode: "df",
        name: "Dutch Finance (t/m 16 sept. 24)",
        paymentTime: 0,
        provisiePercentage: 15,
        provisie_choices: [
            {prov: 10, interest: 9.99},
            {prov: 12.5, interest: 10.99},
            {prov: 15, interest: 11.99},
            {prov: 17.5, interest: 12.99},
            {prov: 20, interest: 13.99},
            {prov: 25, interest: 14.99},
        ],
    },
    {
        shortcode: "dfrr",
        name: "Dutch Finance rekenrente",
        paymentTime: 0,
        provisiePercentage: 5,
        dynamicInterest: true,
        // maxInterest: 12,
        interestIndex: 0,
        basicInterest: [10.9, 10.1, 9.7, 9.5],
        conditions: [
            "leaseAmount<=12499",
            "leaseAmount>=12500&&leaseAmount<=24999",
            "leaseAmount>=25000"
        ],
        durationMax: 60,
        clientInterestMax: 12,
        provisie_choices: [
            {prov: 1, interest: ""},
            {prov: 1.5, interest: ""},
            {prov: 2, interest: ""},
            {prov: 2.5, interest: ""},
            {prov: 3, interest: ""},
            {prov: 3.5, interest: ""},
            {prov: 4, interest: ""},
            {prov: 4.5, interest: ""},
            {prov: 5, interest: ""},
            {prov: 5.5, interest: ""},
            {prov: 6, interest: ""},
            {prov: 6.5, interest: ""},
            {prov: 7, interest: ""},
        ]
    },
    {
        disabled: false,
        shortcode: "al",
        name: "Alphabet (Maximaal 6%)",
        paymentTime: 1,
        provisiePercentage: "",
        interestFromArr: true,
        interestIndex: 0,
        nominalInterest: true,
        conditions: [
            "leaseAmount>=15000&&leaseAmount<25000",
            "leaseAmount>=25000"
        ],
        provisie_choices: [
            {prov: 1, interest: "", interestOptions: [9.74, 9.24, 8.74]}, // interest -> [default; leaseAmount>=15000 && leaseAmount < 25000; leaseAmount >= 25000]
            {prov: 2, interest: "", interestOptions: [9.99, 9.49, 8.99]},
            {prov: 2.5, interest: "", interestOptions: [10.49, 9.99, 9.49]},
            {prov: 3, interest: "", interestOptions: [10.99, 10.49, 9.99]},
            {prov: 3.5, interest: "", interestOptions: [11.49, 10.99, 10.49]},
            {prov: 4, interest: "", interestOptions: [11.99, 11.49, 10.99]},
            {prov: 4.5, interest: "", interestOptions: [12.49, 11.99, 11.49]},
            {prov: 5, interest: "", interestOptions: [12.99, 12.49, 11.99]},
            {prov: 5.5, interest: "", interestOptions: [13.49, 12.99, 12.49]},
            {prov: 6, interest: "", interestOptions: [13.99, 13.49, 12.99]}
        ]
    },
    ,
    {
        shortcode: "al_2",
        name: "Alphabet (Maximaal 4%)",
        paymentTime: 1,
        provisiePercentage: "",
        interestFromArr: true,
        interestIndex: 0,
        nominalInterest: true,
        conditions: [
            "leaseAmount>=15000&&leaseAmount<25000",
            "leaseAmount>=25000"
        ],
        provisie_choices: [
            {prov: 1, interest: "", interestOptions: [9.74, 9.24, 8.74]}, // interest -> [default; leaseAmount>=15000 && leaseAmount < 25000; leaseAmount >= 25000]
            {prov: 2, interest: "", interestOptions: [9.99, 9.49, 8.99]},
            {prov: 2.5, interest: "", interestOptions: [10.49, 9.99, 9.49]},
            {prov: 3, interest: "", interestOptions: [10.99, 10.49, 9.99]},
            {prov: 3.5, interest: "", interestOptions: [11.49, 10.99, 10.49]},
            {prov: 4, interest: "", interestOptions: [11.99, 11.49, 10.99]},
        ]
    },
    {
        shortcode: "fi",
        name: "Findio",
        paymentTime: 0,
        provisiePercentage: "",
        dynamicInterest: true,
        interestIndex: 0,
        basicInterest: [9.99, 8.99, 7.99, 7.49], // [default, conditions[0], conditions[1], conditions[2]]
        conditions: [
            "leaseAmount>=5000&&leaseAmount<=7499",
            "leaseAmount>=7500&&leaseAmount<=14999",
            "leaseAmount>=15000&&leaseAmount<250000"
        ],
        provisie_choices: [
            {prov: 1, interest: ""},
            {prov: 1.5, interest: ""},
            {prov: 2, interest: ""},
            {prov: 2.5, interest: ""},
            {prov: 3, interest: ""},
            {prov: 3.5, interest: ""},
            {prov: 4, interest: ""},
            {prov: 4.5, interest: ""},
            {prov: 5, interest: ""}
        ],
    },
    {
        shortcode: "fi_e",
        name: "Findio (Elektrisch tarief)",
        paymentTime: 0,
        provisiePercentage: "",
        dynamicInterest: true,
        interestIndex: 0,
        basicInterest: [6.99],
        provisie_choices: [
            {prov: 1, interest: ""},
            {prov: 1.5, interest: ""},
            {prov: 2, interest: ""},
            {prov: 2.5, interest: ""},
            {prov: 3, interest: ""},
            {prov: 3.5, interest: ""},
            {prov: 4, interest: ""},
            {prov: 4.5, interest: ""},
            {prov: 5, interest: ""},
        ],
    },
    {
        disabled: true,
        shortcode: "hi",
        name: "Hiltermann Lease (t/m jan. 24)",
        paymentTime: 0,
        provisiePercentage: "",
        provisie_choices: [
            {prov: 2, interest: 9.25},
            {prov: 2, interest: 9.49},
            {prov: 3, interest: 9.75},
            {prov: 3, interest: 9.99},
            {prov: 5.5, interest: 10.25},
            {prov: 8, interest: 10.49},
            {prov: 9, interest: 10.75},
            {prov: 10, interest: 10.99},
            {prov: 10, interest: 11.25},
            {prov: 10, interest: 11.49},
            {prov: 12, interest: 11.75},
            {prov: 13, interest: 11.99},
            {prov: 15.5, interest: 12.49},
            {prov: 18, interest: 12.99},
            {prov: 19, interest: 13.49},
            {prov: 20.5, interest: 13.99},
            {prov: 22, interest: 14.49},
            {prov: 24, interest: 14.99},
            {prov: 26, interest: 15.49},
            {prov: 28, interest: 16.49},
            {prov: 30, interest: 16.99},
        ]
    },
    {
        shortcode: "hi_2",
        name: "Hiltermann Lease (t/m 23 sept. 24)",
        paymentTime: 0,
        provisiePercentage: "",
        provisie_choices: [
            {prov: 2.5, interestWithCondition: 8.99, interest: 9.99}, // interestWithCondition, in case company age => 6 months
            {prov: 5, interestWithCondition: 9.49, interest: 10.49},
            {prov: 7.5, interestWithCondition: 9.99, interest: 10.99},
            {prov: 10, interestWithCondition: 10.49, interest: 11.49},
            {prov: 15, interestWithCondition: 10.99, interest: 11.99},
            {prov: 15, interestWithCondition: 11.49, interest: 12.49},
            {prov: 17.5, interestWithCondition: 11.99, interest: 12.99},
            {prov: 17.5, interestWithCondition: 12.49, interest: 13.49},
            {prov: 20, interestWithCondition: 12.99, interest: 13.99},
            {prov: 20, interestWithCondition: 13.49, interest: 14.49},
            {prov: 22.5, interestWithCondition: 13.99, interest: 14.99},
            {prov: 22.5, interestWithCondition: 14.49, interest: 15.49},
            {prov: 25, interestWithCondition: 14.99, interest: 15.99},
            {prov: 27.5, interestWithCondition: 15.49, interest: 16.49},
            {prov: 27.5, interestWithCondition: 15.99, interest: 16.99},
            {prov: 30, interestWithCondition: 16.49, interest: 17.49},
            {prov: 30, interestWithCondition: 16.99, interest: 17.99},
        ]
    },
    {
        shortcode: "hi_3",
        name: "Hiltermann Lease (vanaf 23 sept. 24)",
        paymentTime: 0,
        provisiePercentage: "",
        provisie_choices: [
            {prov: 2.5, interest: 9.99}, // leaseAmount>=40000, interest -1%, AND companyAge>=6  interest -1.5%
            {prov: 5, interest: 10.49},
            {prov: 7.5, interest: 10.99},
            {prov: 10, interest: 11.49},
            {prov: 15, interest: 11.99},
            {prov: 15, interest: 12.49},
            {prov: 17.5, interest: 12.99},
            {prov: 17.5, interest: 13.49},
            {prov: 20, interest: 13.99},
            {prov: 20, interest: 14.49},
            {prov: 22.5, interest: 14.99},
            {prov: 22.5, interest: 15.49},
            {prov: 25, interest: 15.99},
            {prov: 27.5, interest: 16.49},
            {prov: 27.5, interest: 16.99},
            {prov: 30, interest: 17.49},
            {prov: 30, interest: 17.99},
        ]
    }
];