import { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Popup from "components/ui/Popup/Popup";
import Spinner from "components/ui/Spinner/Spinner";
import Alert from "components/ui/Alert/Alert";
import { dossierActions } from "store/dossier-slice";
import { userLogout } from "store/auth-actions";
import useAuth from "hooks/use-auth";

import useHead from "hooks/use-head";
import Container from "components/hoc/Container";
import WebSocketMessage from "routes/crm/components/WebSocketMessage/WebSocketMessage";
import { getInitials, getUserName } from "lib/helpers";
import { getSalutation } from "lib/voertuig";

const Crm = (props) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    useHead({title: "SLD - Lease Management Systeem CRM"});

    const  dispatch = useDispatch();
    const { role, userDetails } = useAuth();

    const initials = getInitials(userDetails);

    const { totalRecords } = useSelector(state => state.counter);
    
    useEffect(() => {
        document.body.classList.add("crm");
    },[role, userDetails]); 

    const logoutHandler = () => {
        dispatch(userLogout());
        dispatch(dossierActions.reset());
      };

    const linkClasses = " px-8 grid grid-cols-5 justify-between gap-5 py-3 xborder-b xborder-slate-200 xlg:border-none lg:font-medium text-sm hover:bg-[#1A2A52] hover:text-white";

    return (
        <>
        <div className="bg-white py-5 hidden lg:flex fixed left:0 border-l border-b border-slate-300  w-full z-20">
            <div className="mx-6 flex flex-row gap-x-3 items-center">
                <img src="/SLD_logo.png" height={`${menuIsOpen ? '50' : '42'}`} width={`${menuIsOpen ? '120' : '114'}`} alt="Logo" />
                <div className={`flex flex-row gap-x-3 py-2 text-xs text-slate-500`}>
                    <span>Versie {process.env.REACT_APP_VERSION}</span>
                    <span className="fa fa-arrows-rotate cursor-pointer" onClick={() => window.location.reload()}></span>
                </div>
            </div>

{role && role.includes("Admin") &&
            <Container className="flex flex-row justify-end gap-x-10 items-center">
                    <WebSocketMessage />

                    <NavLink to="inbox" className={({ isActive }) => ['flex flex-row gap-x-1 items-center', (isActive ? 'font-semibold' : 'text-slate-700')].join(" ")}>
                        <span className="text-lg">Inbox</span>
                        <span className="">({totalRecords.totalInbox})</span>
                    </NavLink>
                    <NavLink to="sales" className={({ isActive }) => ['flex flex-row gap-x-1 items-center', (isActive ? 'font-semibold' : 'text-slate-700')].join(" ")}>
                        <span className="text-lg">Sales</span>
                        <span className="">({totalRecords.totalSales})</span>
                    </NavLink>
                    <NavLink to="getekende-offertes" className={({ isActive }) => ['flex flex-row gap-x-1 items-center', (isActive ? 'font-semibold' : 'text-slate-700')].join(" ")}>
                        <span className="text-lg">Getekende offertes</span>
                        <span className="">({totalRecords.totalSignedOffers})</span>
                    </NavLink>
                    <NavLink to="contract-afronding" className={({ isActive }) => ['flex flex-row gap-x-1 items-center', (isActive ? 'font-semibold' : 'text-slate-700')].join(" ")}>
                        <span className="text-lg">Contract afronding</span>
                        <span className="">({totalRecords.totalContractClosing})</span>
                    </NavLink>
                    <div className="group relative select-none flex flex-row items-center justify-center font-semibold cursor-pointer">
                        {userDetails.profileImage ? <img className="rounded-full w-[36px] h-[36px]" src={userDetails.profileImage} /> : <span className="bg-slate-500 rounded-full w-[36px] h-[36px] flex flex-row items-center justify-center text-center text-white">{initials}</span>}
                        <div className="hidden gap-y-5 group-hover:flex flex-col p-5 rounded-xl shadow-xl items-center justify-center text-center absolute top-full z-20 bg-slate-100 right-0 pt-10 w-[260px]">
                            {userDetails.profileImage ? <img className="rounded-full w-[100px] h-[100px]" src={userDetails.profileImage} /> : <span className="bg-slate-500 rounded-full w-[100px] h-[100px] flex flex-row items-center justify-center text-center text-white text-3xl">{initials}</span>}
                            <div>{getSalutation()} {userDetails?.firstName ? userDetails.firstName : ""}!</div>
                            <div><NavLink to="mijn-profiel" className="btn btn-secondary">Profiel wijzigen</NavLink></div>
                        </div>
                    </div>

                    {/* <NavLink to="mijn-profiel" className="select-none w-[36px] h-[36px] flex flex-row items-center justify-center font-semibold text-white rounded-full bg-black cursor-pointer">
                        
                    </NavLink> */}

                    {/* <NavLink to="notificaties" className={({ isActive }) => ['flex flex-row gap-x-1 items-center', (isActive ? 'font-semibold' : 'text-slate-700')].join(" ")}>
                        <span className="text-lg">Notificaties</span>
                        <span className="">(x)</span>
                    </NavLink> */}
            </Container>
}
        </div>

        <div className="lg:grid lg:grid-cols-10">
            <Spinner />
            <Alert />
            {menuIsOpen && <div onClick={() => setMenuIsOpen(false)} className="fixed top-0 right-0 w-full h-full bg-black opacity-80 lg:hidden z-40"></div>}

            <div className={`lg:w-[320px] bg-white border-r border-slate-200 lg:h-screen lg:fixed z-10 text-white xl:pb-5 lg:col-span-3 xl:col-span-2`}>
                {!menuIsOpen && (
                <div className="flex flex-row justify-between items-center lg:hidden">
                    <img height="20" width="120" src="/SLD_logo.png" alt="Logo" />
                    <span className="fa fa-bars text-black text-3xl px-3 cursor-pointer select-none" onClick={() => setMenuIsOpen(true)}></span>
                </div>    
                )}

                <div className={`${menuIsOpen ? 'right-0' : '-right-full lg:right-0'} lg:mt-5 bg-white text-black fixed lg:relative transition-all z-40 top-0 lg:flex flex-col h-full max-h-screen`}>
                    <div className="flex flex-row xp-0 justify-between p-5">
                        <img src="/SLD_logo.png" height={`${menuIsOpen ? '50' : '42'}`} width={`${menuIsOpen ? '120' : '114'}`} alt="Logo" />
                        {menuIsOpen && <span className="fa fa-xmark items-center text-3xl px-3 cursor-pointer lg:hidden select-none" onClick={() => setMenuIsOpen(false)}></span>}
                    </div>
<div className="flex flex-col overflow-y-auto h-5/6 scrollbar xmb-20 lg:mb-inherit lg:pb-0">                    
{role.includes("Admin") &&
                    <>
                    <div>
                        <NavLink end onClick={() => setMenuIsOpen(false)} to="/crm" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-house"></span><span className="col-span-4">Home</span></NavLink>
                    <NavLink onClick={() => setMenuIsOpen(false)} to="dealers" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-list"></span><span className="col-span-4">Autobedrijven</span></NavLink>
                    <NavLink onClick={() => setMenuIsOpen(false)} to="verkopen" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-handshake"></span><span className="col-span-4">Verkopen</span></NavLink>

                    <div className="lg:hidden">
                    <NavLink to="inbox" onClick={() => setMenuIsOpen(false)} className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}>
                        <span className=""></span>
                        <span className="col-span-4">Inbox ({totalRecords.totalInbox})</span>
                    </NavLink>
                    <NavLink to="sales" onClick={() => setMenuIsOpen(false)} className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}>
                        <span className=""></span>
                        <span className="col-span-4">Sales ({totalRecords.totalSales})</span>
                    </NavLink>
                    <NavLink to="getekende-offertes" onClick={() => setMenuIsOpen(false)} className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}>
                        <span className=""></span>
                        <span className="col-span-4">Getekende offertes ({totalRecords.totalSignedOffers})</span>
                    </NavLink>
                    <NavLink to="contract-afronding" onClick={() => setMenuIsOpen(false)} className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}>
                        <span className=""></span>
                        <span className="col-span-4">Contract afronding ({totalRecords.totalContractClosing})</span>
                    </NavLink>
                    </div>

                    <NavLink onClick={() => setMenuIsOpen(false)} to="klanten" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-address-card"></span><span className="col-span-4">Klanten</span></NavLink>
                    <NavLink onClick={() => setMenuIsOpen(false)} to="partners" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-user-group"></span><span className="col-span-4">Partners</span></NavLink>
                    <NavLink onClick={() => setMenuIsOpen(false)} to="dashboard" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-chart-line"></span><span className="col-span-4">Dashboard</span></NavLink>

                    <NavLink onClick={() => setMenuIsOpen(false)} to="selectie" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-magnifying-glass"></span><span className="col-span-4">Selectie</span></NavLink>
                    <NavLink onClick={() => setMenuIsOpen(false)} to="export" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-file-export"></span><span className="col-span-4">Export</span></NavLink>
                    <NavLink onClick={() => setMenuIsOpen(false)} to="activiteiten" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-timeline"></span><span className="col-span-4">Activiteiten</span></NavLink>
                    <NavLink onClick={() => setMenuIsOpen(false)} to="status" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-signal-bars"></span><span className="col-span-4">Status</span></NavLink>

                    {/* <NavLink onClick={() => setMenuIsOpen(false)} to="todo" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-list-check"></span><span className="col-span-4">Todo</span></NavLink> */}
                    </div>

                    <div className={`hidden ${menuIsOpen ? 'lg:block' : 'mb-auto'}`}>
                        {/* <NavLink onClick={() => setMenuIsOpen(false)} to="selectie" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-magnifying-glass"></span><span className="col-span-4">Selectie</span></NavLink> */}
                        {/* <NavLink onClick={() => setMenuIsOpen(false)} to="export" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-file-export"></span><span className="col-span-4">Export</span></NavLink> */}
                        {/* <NavLink onClick={() => setMenuIsOpen(false)} to="activiteiten" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-magnifying-glass"></span><span className="col-span-4">Activiteiten</span></NavLink> */}
                    </div>
                    </>
                    }
                    {role.includes("SuperAdmin") &&
                    <>
                    <div>
                    <NavLink to="gebruikers" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-users"></span><span className="col-span-4">Gebruikers</span></NavLink>                   
                    <NavLink to="rollen" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-lock"></span><span className="col-span-4">Rollen</span></NavLink>
                    <NavLink to="mailtemplates" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-envelopes"></span><span className="col-span-4">Mailtemplates</span></NavLink>
                    {/* <NavLink to="calculator" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-calculator"></span><span className="col-span-4">Calculator</span></NavLink> */}
                    <NavLink to="tokens" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-key"></span><span className="col-span-4">API keys</span></NavLink>
                    <NavLink to="changelog" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-note"></span><span className="col-span-4">Changelog</span></NavLink>
                    <NavLink to="algemene-voorwaarden" className={(navData) => (navData.isActive ? "bg-[#1A2A52] text-white" : "")+linkClasses}><span className="fa fa-book"></span><span className="col-span-4">Algemene voorwaarden</span></NavLink>
                    </div>
                    </>
                    }
                    <div onClick={logoutHandler} className={linkClasses+" cursor-pointer mt-auto"}>
                        <span className="fa fa-right-from-bracket"></span>
                        <span className="col-span-4">Uitloggen</span>    
                        
                    </div> 
                </div>
                </div>
            </div>
            <div className="lg:left-[320px] top-0 xl:top-16 relative col-span-10 lg:col-span-7 p-3 lg:p-10">
                <Popup /> 
                <Outlet />
            </div>
        </div>
                    
        </>
    )
}

export default Crm;