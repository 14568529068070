import { useState, useReducer, useEffect } from "react";
import { useSelector } from "react-redux";
import { PMTx, rate, calcExPrice ,calcLeaseAmount, calcProvisieAmount, getInterestIndex, valuesToNoDot, calcNominalInterest, calcVat, valuesToStr, getInterestIndexFromConditions, calcVat2, calcMaxLastAmount, calcFinalPaymentDefault, calcFinalPaymentMax } from "components/Calculator/helpers";
import { BANK_SETTINGS } from "components/Calculator/banksettings";
import useBankCalculator from "./use-bank-calculator";
import { noDotAndInteger } from "lib/helpers";
import { useLocation } from "react-router-dom";

const DEFAULT_CHOSENBANK = "df_3";
const DEFAULT_INTEREST = 11.99;
const DEFAULT_PAYMENT_TIME = 0; // 0=end of period, 1=begin of period
const DEFAULT_DURATION = "60";
const DEFAULT_TYPEOFDEAL = "btw";
const DEFAULT_PROVISIEPERCENTAGE = Object.values(BANK_SETTINGS).filter(item => item.shortcode==="df_3")[0].provisiePercentage;

const combinedReducer = (state, action) => {         
       switch(true) {
        case /(CALC_AND_UPDATE_PROVISIES|CHANGE_BANK)/.test(action.type):
            var chosenBank = action.payload.calcSettings.chosenBank;
            var bankSettingsWithoutCurrent = state.bankSettings.filter(bank => bank.shortcode!==chosenBank);    
            var bankSettingsCurrentBank = state.bankSettings.filter(bank => bank.shortcode===chosenBank)[0];    

            return {
                bankSettingsOrg: state.bankSettingsOrg,
                bankSettings: [
                    ...bankSettingsWithoutCurrent,
                    {
                        ...bankSettingsCurrentBank,
                        provisie_choices: [...action?.payload?.provisie_choices]
                    }
            
                ],
                calcSettings: {
                    ...state.calcSettings,
                    ...action?.payload?.calcSettings
                }
            }
        default:
            return {
                bankSettingsOrg: state.bankSettingsOrg,
                bankSettings: state.bankSettings,
                calcSettings: {
                    ...state.calcSettings,
                    ...action.payload.calcSettings
                }
            } 
    }
}

const initialLoader = (state) => {
    return {
        ...state
    }       
}

const useCalculator = (props) => {   
    const location = useLocation();

    useEffect(() => {
        // set amountTouched, on inventory cars by default
        if(location?.state?.amountTouched) {
            setAmountTouched(true);
            setAmountExTouched(true);
        }
    }, []);

    const { getCalculation } = useBankCalculator();

    const [init, setInit] = useState(true);
    const [amountTouched, setAmountTouched] = useState(false);
    const [amountExTouched, setAmountExTouched] = useState(false);

    // enable for fetch bank settings from API
    // useEffect(() => {
    //     if(!init) return;
    //     const getInitBankData = async () => {
    //         const data = await getCalculation();
    //         setBankSettings(data);
    //         setInit(false);
    //     }
    //     getInitBankData();
    // }, []);

    var storedCalcDetail;

    if(Storage) {
        try {
            storedCalcDetail = JSON.parse(sessionStorage.getItem("calcDetail"));
        } catch {};
    }

    var { vehicleType } = useSelector(state => state.deal.vehicleDetail);
    if(vehicleType==="Bedrijfsauto") vehicleType = "BEDRIJF";
    if(vehicleType==="Personenauto") vehicleType = "AUTO";

    var { typeOfDeal, restBPM, typeOfDeal, durationMax, amountpurchase, leaseAmount } = useSelector(state => state.deal.calcDetail);
    var { dealerCategory } = useSelector(state => state.dealer.dealer);


    var duration;
    if(parseInt(DEFAULT_DURATION) > durationMax) duration = durationMax; 

    // var { restBPM, age, typeOfDeal } = useSelector(state => state.deal.vehicleDetail);
    // var restBPM = 0;
    // var age = 0;
    // var typeOfDeal = "Marge";

    // var durationMax = "80";
    // var { durationMax } = useSelector(state => state.deal.calcDetail);
    
    const initState = {
        bankSettings: BANK_SETTINGS,
        bankSettingsOrg: BANK_SETTINGS,
        calcSettings: {
            sourceOfDealer: props.sourceOfDealer || "",
            companyAge: props.companyAge,
            age: props.age,
            interestIndex: 0,
            interestFromArr: false,
            dynamicInterest: false,
            basicInterest: "",
            interest: (storedCalcDetail?.interest ? storedCalcDetail.interest : "") || props.interest || DEFAULT_INTEREST || "",
            clientInterestMax: "",
            calcInterest: "",
            commissionTotal: props.commissionTotal || "",
            commissionAmountDealer: props.commissionAmountDealer || "",
            commissionAmountSLD: props.commissionAmountSLD || "",
            chosenBank: props.calcsrc || DEFAULT_CHOSENBANK || "df_3",
            paymentTime: props.paymentTime || DEFAULT_PAYMENT_TIME,
            typeOfDeal: props.typeOfDeal || typeOfDeal || DEFAULT_TYPEOFDEAL,
            vehicleType: vehicleType || props.vehicleType || "AUTO", // AUTO|BEDRIJF
            restBPM: restBPM || props.restBPM || "",
            brutoBPM: props.brutoBPM || "",
            amountpurchase: amountpurchase || (storedCalcDetail?.amountpurchase ? storedCalcDetail.amountpurchase : "") || props.amountpurchase || "",
            amountpurchaseExclVAT: props.amountpurchaseExclVAT || "",
            leaseAmount: leaseAmount || (storedCalcDetail?.leaseAmount ? storedCalcDetail.leaseAmount : "") || props.leaseAmount || "",
            leaseAmountOrg: props.leaseAmountOrg || "",
            downpayment: (storedCalcDetail?.downpayment ? storedCalcDetail.downpayment : "") || props.downpayment || "",
            tradeInAmount: (storedCalcDetail?.tradeInAmount ? storedCalcDetail.tradeInAmount : "") || props.tradeInAmount || "",
            finalpayment: (storedCalcDetail?.finalpayment ? storedCalcDetail.finalpayment : "") || props.finalpayment || "",
            finalpaymentMax: props.finalpaymentMax || "",
            duration: (storedCalcDetail?.duration ? storedCalcDetail.duration : "") || props.duration || duration || DEFAULT_DURATION,
            durationMax: props.durationMax || durationMax || 72,
            vat: (storedCalcDetail?.vat ? storedCalcDetail.vat : "") || props.vat || "",
            monthlyamount: (storedCalcDetail?.monthlyamount ? storedCalcDetail.monthlyamount : "") || props.monthlyamount || "",
            repayment: (storedCalcDetail?.repayment ? storedCalcDetail.repayment : "") || props.repayment || "",
            totalToPay: (storedCalcDetail?.totalToPay ? storedCalcDetail.totalToPay : "") || props.totalToPay || "",
            provisiePercentage: (storedCalcDetail?.provisiePercentage ? storedCalcDetail.provisiePercentage : "") || props.provisiePercentage || DEFAULT_PROVISIEPERCENTAGE,
            dealerCategory: dealerCategory || props.dealerCategory || "",
            finalpaymentTouched: props.finalpaymentTouched || false,
            downpaymentTouched: props.downpaymentTouched || false,
            amountpurchaseTouched: false,
            amountpurchaseExTouched: props.amountpurchaseExTouched || false
        }
    }

    const calcAll = (calcInput, current_field) => {
        var chosenBank = calcInput.chosenBank;
        var finalpaymentTouched = calcInput.finalpaymentTouched;      

        var { vat, duration, finalpayment, leaseAmount, downpayment, tradeInAmount, repayment, amountpurchase, amountpurchaseExclVAT, provisiePercentage, clientInterestMax, age, restBPM } = valuesToNoDot({
            age: calcInput.age,
            restBPM: calcInput.restBPM,
            vat: calcInput.vat,
            clientInterestMax: calcInput.clientInterestMax,
            amountpurchase: calcInput.amountpurchase,
            amountpurchaseExclVAT: calcInput.amountpurchaseExclVAT,
            provisiePercentage: calcInput.provisiePercentage,
            downpayment: calcInput.downpayment,
            tradeInAmount: calcInput.tradeInAmount,
            repayment: calcInput.repayment,
            leaseAmount: calcInput.leaseAmount,
            duration: calcInput.duration,
            finalpayment: calcInput.finalpayment
        });

        var bankObj = BANK_SETTINGS.filter(item => item.shortcode===chosenBank)[0];

        var interestIndex = getInterestIndex(chosenBank, leaseAmount);
        calcInput = {
            ...calcInput,
            ...("dynamicInterest" in bankObj && {dynamicInterest: bankObj?.dynamicInterest}),
            ...("interestFromArr" in bankObj && {interestFromArr: bankObj?.interestFromArr}),
            ...("nominalInterest" in bankObj && {nominalInterest: bankObj?.nominalInterest}),
            ...("basicInterest" in bankObj && {basicInterest: bankObj?.basicInterest[interestIndex]}),
            interestIndex: interestIndex,
            paymentTime: bankObj.paymentTime,
        }

        if((!props.recalculate || !amountTouched) && /(amountpurchase|amountpurchaseExclVAT)/.test(current_field) && current_field!=="vat" && /btw/i.test(calcInput.typeOfDeal)) {
            var vatDetails = calcVat2(current_field, amountpurchase, amountpurchaseExclVAT, calcInput.vehicleType);           
            amountpurchase = vatDetails.amountpurchase;
            amountpurchaseExclVAT = vatDetails.amountpurchaseExclVAT;
            vat = vatDetails.vat;
        }
        if((!props.recalculate) && /btw/i.test(calcInput.typeOfDeal)) {
            amountpurchaseExclVAT = calcExPrice(calcInput.typeOfDeal, amountpurchase, restBPM, calcInput.vehicleType);
            vat = amountpurchase - amountpurchaseExclVAT;
        }
        if(/marge/i.test(calcInput.typeOfDeal)) {
            vat = 0;
            amountpurchaseExclVAT = amountpurchase;
        }

        if((!props.recalculate || !amountTouched) && current_field==="vat") amountpurchaseExclVAT = amountpurchase-vat;

        // duration
        var durationMax = calcInput.durationMax;
        // durationMax = calcMaxDuration(age, 6);
        if(duration>durationMax) duration = durationMax;

        if(amountTouched) {
            amountpurchaseExclVAT = noDotAndInteger(calcInput.amountpurchaseExclVAT);
            vat = calcInput.vat;
        }


        leaseAmount = (/(amountpurchase|downpayment|repayment|tradeInAmount)/.test(current_field)) ? calcLeaseAmount(calcInput.typeOfDeal, amountpurchase, amountpurchaseExclVAT, downpayment, tradeInAmount, repayment) : leaseAmount;

        // changed default calc to amountpurchaseExclVAT
        if(current_field==="amountpurchase" && !finalpaymentTouched) finalpayment = calcFinalPaymentDefault(amountpurchaseExclVAT, duration);

        // calc maxlastAmount based on amountpurchaseExclVAT
        var finalpaymentMax = calcFinalPaymentMax(amountpurchaseExclVAT, duration);
        // if(finalpayment>finalpaymentMax) finalpayment = finalpaymentMax;
        if(finalpayment<0) finalpayment = 0; 
        // if(finalpaymentMax<0) finalpaymentMax = 0;
        var interestIndex = getInterestIndex(chosenBank, leaseAmount); 
        // var interestIndex = getInterestIndexFromConditions(leaseAmount, calcInput.conditions);

        var totalToPay = (+vat)+(+downpayment);

        var interest;
        var commissionDetails = {};
        var resultStr = {};
        var monthlyamount;

        if(calcInput?.interestFromArr) {
            // for example ^/al/
            interest = calcInput.interest;
            var nominalInterest = calcNominalInterest(calcInput.interest);
            monthlyamount = PMTx(nominalInterest, duration, leaseAmount, finalpayment, calcInput.paymentTime);
            commissionDetails = calcProvisieAmount(calcInput.sourceOfDealer, chosenBank, duration, monthlyamount, finalpayment, leaseAmount, provisiePercentage, calcInput?.dealerCategory, downpayment, tradeInAmount, repayment);
            resultStr = valuesToStr({amountpurchaseExclVAT, vat, duration, amountpurchase, finalpaymentMax, leaseAmount,downpayment,finalpayment,repayment,tradeInAmount});

            return {
                amountpurchaseExTouched: calcInput.amountpurchaseExTouched,
                amountpurchaseTouched: calcInput.amountpurchaseTouched,
                companyAge: calcInput.companyAge,
                durationMax: durationMax,
                age: age,   
                restBPM: restBPM,
                typeOfDeal: calcInput.typeOfDeal,
                vehicleType: calcInput.vehicleType,
                totalToPay:totalToPay,
                amountpurchase: resultStr.amountpurchase,
                amountpurchaseExclVAT: resultStr.amountpurchaseExclVAT,
                vat: resultStr.vat,
                paymentTime: calcInput.paymentTime,
                interestIndex: interestIndex,
                interest: interest,
                chosenBank: chosenBank,
                leaseAmount: resultStr.leaseAmount,
                tradeInAmount: resultStr.tradeInAmount,
                duration: resultStr.duration,
                repayment: resultStr.repayment,
                downpayment: resultStr.downpayment,
                finalpayment: resultStr.finalpayment,
                finalpaymentMax: resultStr.finalpaymentMax,
                monthlyamount: monthlyamount,
                nominalInterest: nominalInterest,
                commissionAmountDealer: commissionDetails.commissionAmountDealer,
                commissionAmountSLD: commissionDetails.commissionAmountSLD,
                commissionTotal: commissionDetails.commissionTotal,
                dealerCategory: calcInput?.dealerCategory,
                sourceOfDealer: calcInput?.sourceOfDealer
            }
        }

        if(calcInput?.dynamicInterest) {
            // for example "fi" "fi_e" and "dfrr"
            var { basicInterest } = valuesToNoDot({basicInterest: calcInput.basicInterest});
            commissionDetails = calcProvisieAmount(calcInput.sourceOfDealer, chosenBank, duration, monthlyamount, finalpayment, leaseAmount, provisiePercentage, calcInput?.dealerCategory, downpayment, tradeInAmount, repayment);
            var monthlyAmountForRate = PMTx(basicInterest, duration, -leaseAmount-commissionDetails.commissionTotal, finalpayment);
            monthlyAmountForRate = +monthlyAmountForRate;
            var calcInterest = rate(duration, monthlyAmountForRate, leaseAmount, finalpayment);
            calcInterest = +calcInterest;
            var monthlyAmountForRateX = PMTx(basicInterest, duration, -leaseAmount-commissionDetails.commissionTotal, -finalpayment);
            var calcInterestX = rate(duration, monthlyAmountForRateX, leaseAmount, -finalpayment);
            var clientInterest = ((Math.pow((calcInterestX)+1, 12)-1)*100).toFixed(2);
            var leaseAmountForPMT = (+leaseAmount)+(+commissionDetails.commissionTotal);
            monthlyamount = PMTx(basicInterest, duration, leaseAmountForPMT, finalpayment, 0);
            resultStr = valuesToStr({ amountpurchase, amountpurchaseExclVAT, vat, duration, leaseAmount, finalpaymentMax, finalpayment: Math.abs(finalpayment), downpayment, repayment, tradeInAmount });

            return {
                amountpurchaseExTouched: calcInput.amountpurchaseExTouched,
                amountpurchaseTouched: calcInput.amountpurchaseTouched,
                companyAge: calcInput.companyAge,
                durationMax: durationMax,
                age: age,
                restBPM: restBPM,
                typeOfDeal: calcInput.typeOfDeal,
                vehicleType: calcInput.vehicleType,
                totalToPay:totalToPay,
                amountpurchase: resultStr.amountpurchase,
                amountpurchaseExclVAT: resultStr.amountpurchaseExclVAT,
                vat: resultStr.vat,
                interestIndex: interestIndex,
                chosenBank: chosenBank,
                leaseAmount: resultStr.leaseAmount,
                tradeInAmount: resultStr.tradeInAmount,
                repayment: resultStr.repayment,
                downpayment: resultStr.downpayment,
                finalpayment: resultStr.finalpayment,
                finalpaymentMax: resultStr.finalpaymentMax,
                monthlyamount: monthlyamount,
                duration: resultStr.duration,
                interest: calcInput.interest,
                clientInterest: ""+clientInterest,
                commissionAmountDealer: commissionDetails.commissionAmountDealer,
                commissionAmountSLD: commissionDetails.commissionAmountSLD,
                commissionTotal: commissionDetails.commissionTotal,
                dealerCategory: calcInput?.dealerCategory,
                sourceOfDealer: calcInput?.sourceOfDealer
            }
        }

        if(!calcInput?.interestFromArr && !calcInput?.dynamicInterest) {
            // for example "df", "hi"
            monthlyamount = PMTx(calcInput.interest, duration, leaseAmount, finalpayment, calcInput.paymentTime);
            commissionDetails = calcProvisieAmount(calcInput.sourceOfDealer, chosenBank, duration, monthlyamount, finalpayment, leaseAmount, provisiePercentage, calcInput?.dealerCategory, downpayment, tradeInAmount, repayment);
            resultStr = valuesToStr({amountpurchase, amountpurchaseExclVAT, vat, leaseAmount, finalpaymentMax, finalpayment, downpayment, repayment, tradeInAmount, duration });

            return {
                amountpurchaseExTouched: calcInput.amountpurchaseExTouched,
                amountpurchaseTouched: calcInput.amountpurchaseTouched,
                companyAge: calcInput.companyAge,
                durationMax: durationMax,
                age: age,
                restBPM: restBPM,
                typeOfDeal: calcInput.typeOfDeal,
                vehicleType: calcInput.vehicleType,
                totalToPay:totalToPay,
                amountpurchase: resultStr.amountpurchase,
                amountpurchaseExclVAT: resultStr.amountpurchaseExclVAT,
                vat: resultStr.vat,
                chosenBank: chosenBank,
                interestIndex: interestIndex,
                interest: calcInput.interest,
                duration: resultStr.duration,
                monthlyamount: monthlyamount,
                leaseAmount: resultStr.leaseAmount,
                downpayment: resultStr.downpayment,
                finalpayment: resultStr.finalpayment,
                finalpaymentMax: resultStr.finalpaymentMax,
                repayment: resultStr.repayment,
                tradeInAmount: resultStr.tradeInAmount,
                commissionAmountDealer: commissionDetails.commissionAmountDealer,
                commissionAmountSLD: commissionDetails.commissionAmountSLD,
                commissionTotal: commissionDetails.commissionTotal,
                dealerCategory: calcInput?.dealerCategory,
                sourceOfDealer: calcInput?.sourceOfDealer
            }
        }
    }

    const [combinedState, combinedDispatch] = useReducer(combinedReducer, initState, initialLoader);
    // const { leaseAmount, downpayment, finalpayment, duration, chosenBank, provisiePercentage } = combinedState?.calcSettings;
    
    // useEffect(() => {
    //     if(init) return;
    //     validateLeasebedrag(calcState);
    // }, [leaseAmount, downpayment, duration, finalpayment, provisiePercentage]);

    const generateCommissionOptions = (chosenBank, calcObj) => {       
        var chosenBankSettings = Object.values(combinedState?.bankSettingsOrg).filter(item => item.shortcode===chosenBank)[0];
        
        var interestFromArr = (/^al/.test(chosenBank)) ? true : false;
        var dynamicInterest = (/(fi|fi_e|dfrr)/.test(chosenBank)) ? true : false;

        var { paymentTime, provisiePercentage, clientInterestMax, conditions } = chosenBankSettings;
        
        var interestIndex = getInterestIndex(chosenBank, calcObj.leaseAmount);
        // var interestIndex = getInterestIndexFromConditions(calcObj.leaseAmount, conditions);

        var provisies = chosenBankSettings?.provisie_choices;
        var provisie_choices = [];

        var calcResult = {};

        if(interestFromArr) {
            // for example "al"
            provisie_choices = provisies.map(item => {
                var interest = item.interestOptions[interestIndex];
                calcResult = calcAll({...calcObj, chosenBank: chosenBank, interest:interest,paymentTime: paymentTime, provisiePercentage: item.prov, interestFromArr: true, dynamicInterest: false});         
                return {
                    ...item,
                    prov: item.prov,
                    commissionAmountDealer: calcResult.commissionAmountDealer,
                    monthlyamount: calcResult.monthlyamount,
                    interest: ""+interest,
                    nominalInterest: calcResult.nominalInterest
                };   
            });
        } else if (dynamicInterest) {      
            // for example "fi" "fi_e" and "dfrr"
            var basicInterest = chosenBankSettings.basicInterest[interestIndex];
            provisie_choices = provisies.map(({prov, interest}, index) => {             
                calcResult = calcAll({...calcObj, chosenBank: chosenBank, paymentTime: paymentTime, provisiePercentage: prov, interestFromArr:false, dynamicInterest: true, basicInterest: basicInterest});
                return {
                    prov: prov,
                    monthlyamount:calcResult.monthlyamount,
                    commissionAmountDealer:calcResult.commissionAmountDealer,
                    basicInterest: basicInterest,
                    interest: calcResult.clientInterest,
                    calcInterest: calcResult.calcInterest,
                    clientInterest: calcResult.clientInterest
                };
            });
            if(clientInterestMax) provisie_choices = provisie_choices.filter(item => item.clientInterest<=clientInterestMax);
        } else {
            // for example "df", "hi"
            provisie_choices = provisies.map(({prov, interest, interestWithCondition}, index) => {  
                // in case bank=hi_2, and companyAge>=6 months, get interestWithCondition
                if(/hi_2/.test(chosenBank) && parseInt(calcObj.companyAge)>=6) {
                    interest = interestWithCondition;
                }

                if(/hi_3/.test(chosenBank)) {
                    if(parseInt(calcObj?.leaseAmount)>=40000) interest = interest - 1; // subtract 1 percentage, when leaseAmount is gte 40000
                    if(parseInt(calcObj?.companyAge)>=6) interest = interest - 1.5; // subtract 1.5 percentage, when companyAge is gte 6 months
                    interest = Math.round(interest * 100) / 100; // this is required, else the output is: 15.989999999999998...
                }

                calcResult = calcAll({...calcObj, chosenBank: chosenBank, paymentTime: paymentTime, interestFromArr:false, dynamicInterest: false, interest: interest, provisiePercentage: prov});
                
                return {
                    prov: prov,
                    monthlyamount: calcResult.monthlyamount,
                    commissionAmountDealer: calcResult.commissionAmountDealer,
                    interest: interest
                };
            });
        }
        return {
            provisie_choices,
            interestFromArr,
            dynamicInterest,
            paymentTime,
            provisiePercentage
        };
    }

    const changeVehicleType = (evt) => {
        combinedDispatch({payload: {
            calcSettings: {
                vehicleType: evt.target.value
            }
        }});
    };

    const changeBank = (evt) => {
        var chosenBank = evt.target.value;  

        var durationMax = Object.values(combinedState?.bankSettingsOrg).filter(item => item.shortcode===chosenBank)[0].durationMax || 72;
        var clientInterestMax = Object.values(combinedState?.bankSettingsOrg).filter(item => item.shortcode===chosenBank)[0].clientInterestMax || "";

        var calculatedData = calcAll({...combinedState.calcSettings, clientInterestMax, durationMax, interestFromArr, dynamicInterest, provisiePercentage, chosenBank: chosenBank });
        var { interestFromArr, dynamicInterest, paymentTime, provisiePercentage, provisie_choices } = generateCommissionOptions(chosenBank, calculatedData);
        if(/^al/.test(chosenBank)) provisiePercentage = 3;
        if(chosenBank==="fi") provisiePercentage = 3.5;
        if(chosenBank==="fi_e") provisiePercentage = 3.5;
        if(/^hi/.test(chosenBank)) provisiePercentage = 10;

        combinedDispatch({type: "CHANGE_BANK", updateBankSettings: true, payload: {
            provisie_choices: provisie_choices,
            calcSettings: {
                ...calculatedData,
                chosenBank: chosenBank,
                durationMax: durationMax,
                paymentTime: paymentTime,
                clientInterestMax: clientInterestMax,
                monthlyamount: "",
                provisiePercentage: provisiePercentage,
                interestFromArr: interestFromArr,
                dynamicInterest: dynamicInterest,
                paymentTime: paymentTime
            }
        }});
    }

    const loadCalculationFromStorageData = () => {
        var calcSettings = combinedState.calcSettings;
        var chosenBank = combinedState.calcSettings.chosenBank;

        generateCommissionOptions(chosenBank, calcSettings);
        var calculatedData = calcAll(calcSettings);
        var { interestFromArr, dynamicInterest, paymentTime, provisiePercentage, provisie_choices } = generateCommissionOptions(chosenBank, calculatedData);
        
        combinedDispatch({type: "CALC_AND_UPDATE_PROVISIES", updateBankSettings: true, payload: {
            provisie_choices: provisie_choices,
            bankSettings: {
                interestFromArr: interestFromArr,
                dynamicInterest: dynamicInterest
            },
            calcSettings: {
                ...calculatedData,
                paymentTime: paymentTime
            }
        }});

    };

    const changeCalcField = (evt) => {
        var fieldName = evt.target.name;
        var fieldValue = evt.target.value;

        var vehicleType = combinedState.calcSettings.vehicleType;
        var typeOfDeal = combinedState.calcSettings.typeOfDeal;

        var scenario;
        if(/bedrijf/i.test(vehicleType)) {
            if(/btw/i.test(typeOfDeal)) scenario = 1;
            if(/marge/i.test(typeOfDeal)) scenario = 2;
        } else {
            // personenauto
            if(/btw/i.test(typeOfDeal)) scenario = 3;
            if(/marge/i.test(typeOfDeal)) scenario = 4;
        }

        /* scenario's
            1: Bedrijfswagen + btw   -> Aanschaf incl.btw grayed out
                                     -> btw grayed out
                                     -> When changing excl.btw calculate btw and incl.btw

            2: Bedrijfswagen + marge -> No aanschaf incl.btw shown
                                     -> No btw shown
                                     -> Showing aanschaf excl.btw

            3: Personenauto + btw    -> First time fill in of aanschaf incl.btw calculate excl.btw based on rest bpm
                                     -> Aanschaf incl.btw available to edit
                                     -> btw grayed out
                                     -> When changing excl.btw don’t calculate incl.btw
                                     -> When changing incl.btw don’t calculate excl.btw
                                    
            4: Personenauto + marge  -> No aanschaf incl.btw shown
                                     -> No btw shown
                                     -> Showing aanschaf excl.btw
        */

        var calculatedData = calcAll(
            {...combinedState.calcSettings,
                [fieldName]: fieldValue
            }, fieldName);
       
        // logica for financiering aanpassen
        if( scenario===3 && (props.recalculate || amountTouched) ) {       
           if(fieldName==="amountpurchaseExclVAT") {
            const { amountpurchaseExclVAT } = valuesToNoDot({amountpurchaseExclVAT: fieldValue});
            var amountpurchase = amountTouched ? calculatedData.amountpurchase : props.fixedAmountPurchase;
            calculatedData.vat = amountpurchase-amountpurchaseExclVAT;
            calculatedData.amountpurchase = amountpurchase;
            calculatedData.amountpurchaseExclVAT = amountpurchaseExclVAT;
            calculatedData.totalToPay = noDotAndInteger(calculatedData.vat) + noDotAndInteger(calculatedData.downpayment);
           }
           if(fieldName==="amountpurchase") {
            const { amountpurchase } = valuesToNoDot({amountpurchase: fieldValue});
            var amountpurchaseExclVAT = amountExTouched ? calculatedData.amountpurchaseExclVAT : props.fixedAmountPurchaseExclVAT;
            calculatedData.vat = amountpurchase-amountpurchaseExclVAT;
            calculatedData.totalToPay = noDotAndInteger(calculatedData.vat) + noDotAndInteger(calculatedData.downpayment);
            calculatedData.amountpurchaseExclVAT = amountpurchaseExclVAT;
           }
        }

        var { interestFromArr, dynamicInterest, paymentTime, provisiePercentage, provisie_choices } = generateCommissionOptions(combinedState.calcSettings.chosenBank, calculatedData);
        
        combinedDispatch({type: "CALC_AND_UPDATE_PROVISIES", updateBankSettings: true, payload: {
            provisie_choices: provisie_choices,
            bankSettings: {
                interestFromArr: interestFromArr,
                dynamicInterest: dynamicInterest
            },
            calcSettings: {
                ...calculatedData,
                paymentTime: paymentTime,
                ...(fieldName!=="finalpayment" && {[fieldName]: fieldValue}),
                ...(fieldName==="finalpayment" && {finalpaymentTouched: true}),
            }
        }});

    }

    const changeInterest = (evt) => {
        var provisiePercentage = evt.target.value;
        var selectedIndex = evt.target.selectedIndex;
        var target = evt.target;
        var currentOption = target[selectedIndex];

        var interest = currentOption?.getAttribute("data-interest") || "";
        var basicInterest = currentOption?.getAttribute("data-basic-interest") || "";
        var nominalInterest = currentOption?.getAttribute("data-nominal-interest") || "";

        var calculatedData = calcAll({...combinedState?.calcSettings, provisiePercentage: provisiePercentage, interest: interest, basicInterest: basicInterest, nominalInterest: nominalInterest}, "interest");

        combinedDispatch({type: "CHANGE_INTEREST", payload: {
            calcSettings: {
                interest: interest,
                monthlyamount: calculatedData.monthlyamount,
                provisiePercentage: provisiePercentage,
                commissionAmountDealer: calculatedData.commissionAmountDealer,
                commissionAmountSLD: calculatedData.commissionAmountSLD,
                commissionTotal: calculatedData.commissionTotal,
            }
        }});
    }

    const changeInterestFromRange = (evt, provisie_choices) => {
        var currentStep = +evt.target.value;
        var currentProvisieChoices = provisie_choices[currentStep];

        var provisiePercentage = currentProvisieChoices.prov;
        var interest = currentProvisieChoices.interest;

        // var basicInterest = currentOption?.getAttribute("data-basic-interest") || "";
        // var nominalInterest = currentOption?.getAttribute("data-nominal-interest") || "";

        var calculatedData = calcAll({...combinedState?.calcSettings, provisiePercentage: provisiePercentage, interest: interest}, "interest");

        combinedDispatch({type: "CHANGE_INTEREST_FROM_RANGE", payload: {
            calcSettings: {
                interest: interest,
                monthlyamount: calculatedData.monthlyamount,
                provisiePercentage: provisiePercentage,
                commissionAmountDealer: calculatedData.commissionAmountDealer,
                commissionAmountSLD: calculatedData.commissionAmountSLD,
                commissionTotal: calculatedData.commissionTotal,
            }
        }});

    }

    const setTouchedField = (evt) => {
        var fieldName = evt.target.name;
        if(evt?.target?.readOnly) return;

        if(fieldName==="amountpurchase") setAmountTouched(true);
        if(fieldName==="amountpurchaseExclVAT") setAmountExTouched(true);

    };

    return {
        calcInput: combinedState?.calcSettings,
        bankSettings: combinedState?.bankSettings,
        changeVehicleType,
        changeBank,
        changeCalcField,
        changeInterest,
        changeInterestFromRange,
        loadCalculationFromStorageData,
        calcLeaseAmount,
        setTouchedField
    }
}

export default useCalculator;